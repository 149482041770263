<template>
  <div v-if="authState && authState.isAuthenticated">
    <nav
      class="navbar navbar-expand-lg bg-light justify-content-between"
    >
      <div class="container-fluid p-1">
        <div class="row">
          <div class="col-sm navbar-left-content navbar-home-icon p-0 ps-2 ms-4 me-2 pe-4">
            <a class="navbar-brand me-0" href="#">
              <a v-on:click="home()" href="#" class="text-decoration-none">
                <img src="@/assets/icons/omni-home.svg" alt="omni home logo">
              </a>
            </a>
          </div>
        </div>

        <div class="navbar-right-content">
          <span class="me-4">{{ userInfo.email }}</span>
          <a class="px-4" href="#" v-on:click="logout()"> Logout </a>
        </div>
      </div>
    </nav>

    <!-- Bottom bar of the dropdown -->
    <div class="nav-extra-content">
      <div class="left-content">
        <span class="inline-block-child pad">Search Connect</span>
        <div class="inline-block-child">
          <ul
            class="nav nav-pills"
            id='navtab'
            role="tablist"
          >
            <li
              class="nav-item"
              role="presentation"
              v-for="(tab) in tabObjArray"
              :key="tab.value"
            >
              <button
                :id="tab.value + 'tab'"
                :data-bs-target="'#' + tab.value"
                :aria-controls="tab.value"
                :class="{ 'active': index === tab.value }"
                @click="tabClick(tab.value)"
                class="nav-link"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-selected="true"
              > {{ tab.text }} </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-content">
        <span class="me-2">Client Name</span>
        <clients-dashboard-select class="right bm-dropdown-container"></clients-dashboard-select>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  getCurrentInstance, onMounted, computed, ref,
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ClientsDashboardSelect from '../clients/ClientsDashboardSelect.vue'

const store = useStore()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const userInfo = computed(() => store.state.user)
const tabObjArray = ref([])
const router = useRouter()
const index = ref('')
const tabs = ['About', 'Client Admin']

tabObjArray.value = tabs.map((tab) => (
  {
    text: tab,
    value: tab.toLowerCase().replaceAll(' ', '-'),
  }
))

const tabClick = (value) => {
  if (value === 'about') {
    index.value = 'about'
    router.push({ name: 'about' })
  } else if (value === 'client-admin') {
    index.value = 'client-admin'
    router.push({ name: 'clients' })
  }
}

const home = () => {
  window.location = `${window.location.protocol}//${window.location.host}`
}

const logout = async () => {
  await oktaAuth.signOut()
}

const cleanSocialkitGroups = (groups) => {
  const cleanGroups = {}

  groups.forEach((group) => {
    const type = group.includes('_market_') ? 'market' : 'role'
    const cleanGroupText = group.replace(/.+?(_market_|_role_)/, '').replace('_', ' ')
    const value = type === 'market'
      ? cleanGroupText.toUpperCase()
      : cleanGroupText.split(' ').map((val) => val.charAt(0).toUpperCase() + val.substring(1).toLowerCase()).join(' ')

    cleanGroups[type] = value
  })

  return cleanGroups
}

onMounted(async () => {
  const authenticated = await oktaAuth.isAuthenticated()

  if (authenticated) {
    if (!store.state.user.email) {
      const user = await oktaAuth.getUser()

      // Fetch user details from the Custom OKTA storage Provider
      let userOktaToken = oktaAuth.tokenManager.storage.storageProvider.getItem('okta-token-storage')

      userOktaToken = JSON.parse(userOktaToken)

      const groups = cleanSocialkitGroups(userOktaToken.accessToken.claims.socialkit_groups)
      const userDetails = {
        email: user.email,
        ...groups,
        role: groups.role.toLowerCase() === 'sa' ? 'Super Admin' : groups.role,
      }

      store.commit('setUserInfo', userDetails)
    }
  }
})
</script>
<style scoped>
 .navbar {
    background-color: #1F324F !important;
    color: white;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
  }

  .navbar .container-fluid {
    background-color: #1F324F !important;
  }

  .navbar-right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .navbar-left-content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  .navbar-right-content a {
    border-left: 3px solid white;
    text-decoration: none;
    color: white;
  }

  .navbar-home-icon {
     border-right: 3px solid white;
     margin-right: 15px;
  }
  .nav-extra-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .nav-extra-content .left-content {
    margin-left: 10rem;    padding-right: 10px;
    padding-left: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .nav-extra-content .right-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .nav-extra-content .right-content span {
    font-size: 0.9rem !important;
  }

  .nav-extra-content .right-content .btn {
    font-size: 0.9rem !important;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .inline-block-child {
  display: inline-block;
  }
  .pad{
    border-right: 2px solid #CECECE;
    padding-right: 25px;
  }
  .right{
    padding-right: 25px;
  }
</style>
