import { createStore } from 'vuex'
import axios from 'axios'

const defaultState = {
  user: {
    email: '',
    market: '',
    role: '',
  },
  clients: {
    selected: {
      clientName: '',
      clientId: '',
    },
    all: [],
    fetching: false,
  },
  adAccountCampaigns: {},
  isSideNavExpand: true,
}
export default createStore({
  state() {
    return defaultState
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.user.email = userInfo.email

      if (userInfo && Object.keys(userInfo).length >= 1) {
        state.user.market = userInfo.market
        state.user.role = userInfo.role
      }
    },

    setUserEmail(state, email) {
      state.user.email = email
    },
    setClientsFetching(state, fetching) {
      state.clients.fetching = fetching
    },
    setClients(state, clients) {
      state.clients.all = clients
    },
    setClientAdAccounts(state, { clientId, adAccounts }) {
      if (!state.clients[clientId]) {
        state.clients[clientId] = {}
      }
      state.clients[clientId].adAccounts = adAccounts
    },
    setAdAccountCampaigns(state, { adAccountId, campaigns }) {
      if (!state.adAccountCampaigns[adAccountId]) {
        state.adAccountCampaigns[adAccountId] = []
      }
      state.adAccountCampaigns[adAccountId] = campaigns
    },

    setSideNavExpandState(state, sideNavState) {
      state.isSideNavExpand = sideNavState
    },
  },
  actions: {
    getUserClients({ commit }, accessToken) {
      commit('setClientsFetching', true)
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/`
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      axios
        .get(API_URL)
        .then((response) => {
          commit('setClients', response.data)
        })
        .finally(() => commit('setClientsFetching', false))
    },
    getClientAdAccounts({ commit }, { clientId, accessToken }) {
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/ad_accounts?client_id=${clientId} `
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      const adAccounts = []
      axios
        .get(API_URL)
        .then((response) => response.data)
        .then((data) => {
          data.forEach((x) => {
            adAccounts.push(
              {
                adAccountName: x.ad_account_name,
                adAccountId: x.ad_account_id,
              },
            )
          })
          commit('setClientAdAccounts', { clientId, adAccounts })
        })
    },
    getClientAdAccountCampaigns({ commit }, { clientId, adAccountId, accessToken }) {
      const campaigns = []
      const adAccountParam = adAccountId ? `&ad_account_id=${adAccountId}` : ''
      // eslint-disable-next-line
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/campaigns?client_id=${clientId}${adAccountParam}`
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      axios
        .get(API_URL)
        .then((response) => response.data)
        .then((data) => {
          data.forEach((x) => {
            campaigns.push({ campaignName: x.campaign_name, campaignId: x.campaign_id })
          })
          commit('setAdAccountCampaigns', { adAccountId, campaigns })
        })
    },
  },
  getters: {
    // Fetch AdAccounts for a client.
    // If the clientId is null or clientId key is not present then return empty list.
    clientAdAccounts: (state) => (clientId) => {
      if (!state.clients[clientId]) {
        return []
      }
      if (!state.clients[clientId].adAccounts) {
        return []
      }
      return state.clients[clientId].adAccounts
    },
    // Fetch Campaigns for a given AdAccountId.
    // If the AdAccount null or not present in the state then return empty list.
    adAccountCampaigns: (state) => (adAccountId) => {
      if (!adAccountId) {
        return []
      }
      if (!state.adAccountCampaigns[adAccountId]) {
        return []
      }
      return state.adAccountCampaigns[adAccountId]
    },
    userClientIsFetch: (state) => state.clients.fetching,
  },
})
