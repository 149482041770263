<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full mt-5">
      <div class="pair-content">
        <img
          class="ms-2 b center"
          src="@/assets/icons/error-grey.svg"
          alt="Error Icon"
          v-tooltip.bottom="`Query Logic:
          Join SEO & PPC Tables on ppc searchterm & seo query from the last 7 days and group the searchterms.
            We then filter the data to only show searchterms where the seo position is lower than 6 and then sort by paid impressions descending...
             all the fields in the table below are then calculated.`"
        >
        <h1>Organic Ranking Opportunities based on Paid impression</h1>
      </div>
      <p class="text-xs">Top 50 Paid keywords by impressions with Organic ranking greater than 6  </p>
      <div class="blurb">
        <p>
          Keywords with the highest amount of Paid impressions and their corresponding Organic rankings.
        </p>
        <p>
          Those with high paid impressions but poor organic ranking present the opportunity for bolstering on-site content to improve SERP prominence.
        </p>
      </div>

      <button
        @click="loadPaidData(true)"
        class="bg-cyan-900 hover:bg-cyan-700 text-white py-1 px-2 mt-3 rounded"
      >
        {{ !isExportingData ? 'Export Table Data' : 'Downloading...'}}
      </button>

      <div v-if="chartLoaded">
        <MetricsTable
          :tableData=tableData
          :headerData=headerData
          :rowMap=rowMap
          :initialSortIndex="'5'"
          :isSortAscending="false"
        />
      </div>
      <div v-else class="px-5 w-full mt-10">
      <h1>Loading Data... </h1>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable camelcase */
import {
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import MetricsTable from '@/components/charts//MetricsTable.vue'
import { downloadCSVWithResponseBlob } from '@/utils/helperFunctions'

const chartLoaded = ref(null)
const chartFilters = {
  lookback: 7,
  seo_rank: 6,
}
const route = useRoute()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const headerData = [
  'SearchTerm',
  'Org Imps',
  'Org Clicks',
  'Org CTR',
  'Avg Rank',
  'Paid Imps',
  'Paid Clicks',
  'Paid CPC',
  'Paid CTR',
  'Paid Cost (£)',
]
const tableData = ref([])
const rowMap = [
  'searchterm',
  'organic_impressions',
  'organic_clicks',
  'organic_ctr',
  'organic_avg_rank',
  'paid_impressions',
  'paid_clicks',
  'paid_cpc',
  'paid_ctr',
  'paid_cost',
]

const isExportingData = ref(false)

function loadPaidData(downloadAsCSV = false) {
  if (downloadAsCSV) {
    isExportingData.value = true
  }

  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/ranking_opportunity_on_search_impressions`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`

  const params = {
    client_id: route.params.id,
    lookback: chartFilters.lookback,
    seo_rank: chartFilters.seo_rank,
    csv: downloadAsCSV,
  }

  axios
    .get(API_URL, { params })
    .then((response) => {
      if (downloadAsCSV) {
        downloadCSVWithResponseBlob('organic-opportunities-paid-imps', response.data)

        isExportingData.value = false
      } else {
        tableData.value = response.data
      }
    })
    .finally(() => {
      chartLoaded.value = true
    })
}

onMounted(() => {
  loadPaidData()
})

</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}

</style>
