import { Tooltip } from 'bootstrap'

const tooltip = {
  beforeMount(el, binding) {
    if (binding.modifiers?.truncatedTextOnly && !(el.clientWidth < el.scrollWidth)) {
      return
    }

    // eslint-disable-next-line
    const tooltip = new Tooltip(el, {
      boundary: document.body,
      title: binding.value,
      trigger: 'hover focus',
      placement: binding.arg || 'top',
      customClass: 'custom-tooltip',
    })
  },

  mounted(el) {
    const tooltipEl = Tooltip.getInstance(el)

    el.addEventListener('click', () => {
      tooltipEl.dispose()
    })
  },

  updated(el, binding) {
    el.setAttribute('data-bs-original-title', binding.value)
  },
}

export default tooltip
