<template>
  <div class="container px-4 md:w-2/3 mx-auto mt-3">
    <div class="d-flex justify-content-center pt-3 pb-5">
      <router-link
        :to="{ name: 'login' }"
        class="btn btn-primary"
        v-if="!isAuthenticated"
      >
        <span class="px-2 py-3">Login</span>
      </router-link>
    </div>

    <h4 class="d-flex justify-content-center"> What is searchConnect </h4>
    <p> searchConnect is a custom built OMG Labs solution which makes it easier to optimise investment across paid, owned and earned channels. </p>

    <h4 class="d-flex justify-content-center"> How it all works </h4>
    <p> Finding a way to optimise organic search and drive income growth by reducing wastage in paid media is an ongoing challenge every advertiser faces. Creating a single view of organic/SEO performance and paid search allows us to: </p>
    <ul class="m-10 md:w-2/3 list-disc">
      <li>
        <p> Identify content gaps on your website based on PPC quality score data </p>
      </li>
      <li>
        <p> Make on-site improvements and measure the impact of these changes on quality score and CPC </p>
      </li>
      <li>
        <p> Identify areas where PPC spend can be reinvested due to strong SEO coverage </p>
      </li>
    </ul>
    <p> Connecting keyword, search term and query data from Google Ads and Search Console respectively is a manual, laborious process - which is why we created searchConnect. </p>
    <br>
    <p> searchConnect automates the extraction of this data, integrating the data sets, allowing for a unique, holistic single view of your data which powers a uniques set of custom built dashboard.</p>

    <p class="mt-10"> searchConnect doesn't just enable us to make better investment decisions across paid, owned and earned channels. Other benefits include: </p>
    <ul class="m-10 md:w-2/3 list-disc">
      <li>
        <p> Improved ROI and media saving forecasts </p>
      </li>
      <li>
        <p> Faster briefing processes for digital teams to receive recommendation reports </p>
      </li>
      <li>
        <p> Quantifies the business case for outreach and digital PR by forecasting page rank movement impact </p>
      </li>
      <li>
        <p> Creates shared purpose between SEO, digital, media and PR teams Identifies areas of growth more quickly </p>
      </li>
    </ul>

    <h1 class="d-flex justify-content-center mb-1">Granting Access To Google Services</h1>
    <h4 class="d-flex justify-content-center mb-1"> How we extract performance data </h4>
    <p> searchConnect utilises Google's Auth to access to services and extract data.  Your Account Manager will share the relevant OAuth links and by authenticating and granting access to Search Console, Google Ads or Google Analytics via Google OAuth, you are allowing searchConnect to read data from the granted service. </p>
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'

const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const isAuthenticated = ref(false)

onMounted(async () => {
  isAuthenticated.value = await oktaAuth.isAuthenticated()
})
</script>
