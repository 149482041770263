<script>
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'

export default {
  components: {
    CheckIcon, CrossIcon,
  },
  name: 'ClientTable',
  props: {},
  methods: {
    routeToClientDetails(client) {
      this.$router.push({ name: 'clientDetails', params: { id: client.id } })
    },
    routeToClientDashboards(client) {
      this.$router.push({ name: 'clientDashboard', params: { id: client.id, name: client.name } })
    },
  },
  computed: {
    clients() {
      return this.$store.state.clients.all
    },
  },
  mounted() {
    if (!this.$store.state.clients.all.length) {
      this.$store.dispatch('getUserClients', this.$auth.getAccessToken())
    }
  },
}
</script>
<template>
  <div class="fill-screen flex flex-col mx-auto">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden shadow-md sm:rounded-lg">
          <table class="min-w-full">
            <thead class="bg-gray-100 dark:bg-gray-700">
              <tr class="border-b1">
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  PPC Token
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  SEO Token
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  GA Token
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  Dashboards
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- @click="routeToClientDetails(client)" -->
              <tr
                v-for="client in clients"
                :key="client.id"
                class="hover:bg-slate-50"
              >
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <button @click="routeToClientDetails(client)">
                    <span class="underline">{{ client.name }}</span>
                  </button>
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <check-icon v-if="!!client.ppc_token_id" />
                  <cross-icon v-if="!client.ppc_token_id" />
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <check-icon v-if="!!client.has_seo_token" />
                  <cross-icon v-if="!client.has_seo_token" />
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <check-icon v-if="!!client.ga_token_id" />
                  <cross-icon v-if="!client.ga_token_id" />
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-400
                    whitespace-nowrap
                    dark:text-white
                  "
                >
                  <button
                    class="
                      bg-cyan-400
                      hover:bg-cyan-500
                      text-white
                      py-1
                      px-2
                      rounded
                      text-xs
                      mt-0
                      mb-0
                      align-middle
                    "
                    @click="routeToClientDashboards(client)"
                  >
                    Dashboards
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
tbody {
  box-sizing: border-box;
  display: table-row-group;
  vertical-align: middle;
  border-collapse: separate;
  border-spacing: 0 10px;
}

th {
  font-size: larger !important;
}

tbody tr {
  background-color: #fff;
}
</style>
