<template>
  <div class="max-w-sm rounded overflow-hidden shadow-lg">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ platformName[this.gAuthType] || '' }}</div>
      <p class="text-gray-700 text-base">
        Use the button below to grant search connect access to
        <b>{{ platformName[this.gAuthType] }}</b> via Google OAuth
      </p>
    </div>
    <div class="px-6 pt-4 pb-2">
      <button
        class="
          bg-blue-400
          hover:bg-blue-500
          text-white
          py-1
          px-2
          rounded
          text-xs
          mt-0
          mb-0
          align-middle
        "
        @click="getPpcAuthUrl"

      >
        <span>Get PPC Auth Url</span>
      </button>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'PpcAuthUrlButton',
  props: {
    ppcId: Number,
    gAuthType: String,
  },
  data() {
    return {
      platformName: {
        PPC: 'Google Ads',
        SC: 'Google Search Console',
        GA: 'Google Analytics',
      },
    }
  },
  methods: {
    async getPpcAuthUrl() {
      if (!this.ppcId) {
        return false
      }
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/ppc_creds/${this.ppcId}/signed_url`
      axios.defaults.headers.common.Authorization = `Bearer ${this.$auth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((response) => {
          const authUrl = `${window.location.origin}/ppc-auth/${this.ppcId}/signed-url/${response.data}`
          this.$emit('authUrlFetched', authUrl)
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error)
        })
        .finally()
      return true
    },
  },
}
</script>
