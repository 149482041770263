<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full pt-5">
      <div class="pair-content">
        <img
          class="ms-2 b center"
          src="@/assets/icons/error-grey.svg"
          alt="Error Icon"
          v-tooltip.bottom="`Query Logic:
          Join SEO & PPC Tables on ppc searchterm & seo query from the last 7 days and group the searchterms.
          We then filter the data to only show searchterms where the seo position is lower than 6...
            all the fields in the table below are then calculated.`"
        >
        <h1>Paid Generic Terms to Push :
          {{ tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData.length : tableData.length }}
        </h1>
      </div>
      <p class="text-xs">Top 50 generic SEO keywords by impressions with SEO ranking greater than 6 </p>
      <div class="blurb">
        <p>High volume Organic search terms which aren’t currently being targeted by Paid,
          helping to identify opportunities to improve Paid coverage.</p>
      </div>

      <button
        @click="loadPaidData(true)"
        class="bg-cyan-900 hover:bg-cyan-700 text-white py-1 px-2 mt-3 rounded"
      >
        {{ !isExportingData ? 'Export Table Data' : 'Downloading...'}}
      </button>

      <div v-if="chartLoaded">
        <MetricsTable
          :tableData="tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData : tableData"
          :headerData=headerData
          :rowMap=rowMap
          @search-input-change="tableSearchInputEv"
          :showSearch="true"
          :searchPlaceholder="'Search in SearchTerm'"
          :initialSortIndex="'1'"
          :isSortAscending="false"
        />
      </div>
      <div v-else-if="tableData.length === 0" class="px-5 w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable camelcase */
import {
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import MetricsTable from '@/components/charts//MetricsTable.vue'
import { downloadCSVWithResponseBlob } from '@/utils/helperFunctions'

const chartLoaded = ref(null)
const chartFilters = {
  lookback: 7,
  seo_rank: 6,
}
const route = useRoute()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const headerData = [
  'SearchTerm',
  'Org Imps',
  'Org Clicks',
  'Org CTR',
  'Org Avg Rank',
  'Paid Imps',
  'Paid Clicks',
  'Paid CPC',
  'Paid CTR',
  'Paid Cost (£)',
]
const tableData = ref([])
const tableSearchData = ref([])
const tableSearchText = ref('')
const rowMap = [
  'searchterm',
  'organic_impressions',
  'organic_clicks',
  'organic_ctr',
  'organic_avg_rank',
  'paid_impressions',
  'paid_clicks',
  'paid_cpc',
  'paid_ctr',
  'paid_cost',
]

const isExportingData = ref(false)

function loadPaidData(downloadAsCSV) {
  if (downloadAsCSV) {
    isExportingData.value = true
  }

  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/generic_terms_to_activate`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`

  const params = {
    client_id: route.params.id,
    lookback: chartFilters.lookback,
    seo_rank: chartFilters.seo_rank,
    csv: downloadAsCSV,
  }

  axios
    .get(API_URL, { params })
    .then((response) => {
      if (downloadAsCSV) {
        downloadCSVWithResponseBlob('paid-generic-terms-to-push', response.data)

        isExportingData.value = false
      } else {
        tableData.value = response.data
      }
    })
    .finally(() => {
      chartLoaded.value = true
    })
}

/**
 * handle table search event
 *
 * @param {String} searchText - search text
 */
function tableSearchInputEv(searchText) {
  tableSearchText.value = searchText

  if (searchText !== '') {
    tableSearchData.value = tableData.value
      .filter((data) => data.searchterm.includes(searchText))
  } else {
    tableSearchData.value = []
  }
}

onMounted(() => {
  loadPaidData(false)
})

</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}

</style>
