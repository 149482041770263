<template>
  <div class="container mx-auto px-4 mt-5 pb-3">

    <p class="fs-4 fw-bold mb-4 pt-2">
      Client: {{ client ? client.name : "" }}
    </p>

    <div class="oauth-container">
      <div class="sc-content">
        <div>
          <google-o-auth-button
            class="mb-5"
            :entityId="Number(clientIdFromRoute)"
            :clientId="Number(clientIdFromRoute)"
            :gAuthType="'SC'"
            :platformName="client?.name"
            @token-added="tokenAddedEvent"
          >
            <template #default>
              <client-auth-url-button
                :clientId="Number(clientIdFromRoute)"
                :gAuthType="'SC'"
                @auth-url-fetched="(url) => authUrlFetched(url, 'SC')"
              ></client-auth-url-button>
            </template>
          </google-o-auth-button>

        </div>
      </div>

      <div class="ga-content">
        <google-o-auth-button
          :gAuthType="'GA'"
          :entityId="Number(clientIdFromRoute)"
          :clientId="Number(clientIdFromRoute)"
          @token-added="tokenAddedEvent"
        >
          <template #default>
            <client-auth-url-button
              :clientId="Number(clientIdFromRoute)"
              :gAuthType="'GA'"
              @auth-url-fetched="(url) => authUrlFetched(url, 'GA')"
            ></client-auth-url-button>
          </template>
        </google-o-auth-button>
      </div>

      <div class="pcc-content">
        <google-o-auth-button
          :gAuthType="'PPC'"
          :entityId="Number(clientIdFromRoute)"
          :clientId="Number(clientIdFromRoute)"
          @token-added="tokenAddedEvent"
        >
          <template #default>
            <client-auth-url-button
              :clientId="Number(clientIdFromRoute)"
              :gAuthType="'PPC'"
              @auth-url-fetched="(url) => authUrlFetched(url, 'PPC')"
            ></client-auth-url-button>
          </template>
        </google-o-auth-button>
      </div>
    </div>

    <div v-if="fetchedUrl" class="pb-4">
      <div>
        <button
          class="mb-0 d-flex"
          @click="copySignedUrl"
          @mouseleave="copySignedUrlMouseOver"
          @blur="copySignedUrlMouseOver"
        >
          <span v-if="signedURLCheckIcon" class="d-inline-flex">
            <check-icon/> Copied
          </span>
          <span v-else class="d-inline-flex">
            <copy-icon/> Copy {{ selectedGAuthType }} OAuth URL
          </span>
        </button>
      </div>

      <div
        class="mt-0 p-4 bg-success text-white signed-url-box"
        role="alert"
        ref="signedUrl"
      >
        <span>{{ fetchedUrl }}</span>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import GoogleOAuthButton from '@/components/gOauth/GoogleOAuthButton.vue'
import ClientAuthUrlButton from '@/components/clients/ClientAuthUrlButton.vue'
import CopyIcon from '@/components/icons/CopyIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'

const route = useRoute()
const router = useRouter()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const client = ref(null)
const loading = ref()
const clientIdFromRoute = ref(route.params.id)
const fetchedUrl = ref(null)
const authUrl = ref(null)
const selectedGAuthType = ref('')
const signedURLCheckIcon = ref(false)

function tokenAddedEvent() {
  router.push({ name: 'clients' })
}

function copySignedUrl() {
  signedURLCheckIcon.value = true
  navigator.clipboard.writeText(fetchedUrl.value)
}

function authUrlFetched(authUrlValue, gAuthType) {
  selectedGAuthType.value = gAuthType
  fetchedUrl.value = authUrlValue
}

function copySignedUrlMouseOver() {
  signedURLCheckIcon.value = false
}

function fetchClient(clientId) {
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/${clientId}?=`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL)
    .then((response) => {
      client.value = response.data
      fetchedUrl.value = null
      authUrl.value = ''
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  fetchClient(route.params.id)
  clientIdFromRoute.value = route.params.id
})

</script>
<style scoped>
.oauth-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.signed-url-box {
  border-radius: 5px;
  word-break: break-all;
}
</style>
