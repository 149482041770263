<!-- Text Input Model -->
<!--
  -- A simple input model which contain 3 versions
    -- Input with Left Icon and clearable (x-icon)
    -- Input with clearable (x-icon)
    -- Plain Input

  IMPORTANT
  -- If show left icon is use: Make sure the icon element must contain a an input-left-icon class
-->

<template>
  <div>
    <div v-if="label" class="input-label">
      <span>{{ label }}</span>
    </div>

    <div
      :id="id+'-container'"
      class="input-group"
      :class="
        {
          'active' : isInputActive,
          'disabled': isDisabled,
          'success': isSuccess,
          'error': hasError,
          'soft-disabled': softDisabled,
        }
      "
    >
      <span
        class="input-group-text"
        :id="id"
        v-if="showLeftIcon"
      >
        <slot name="left-icon"></slot>
      </span>

      <input
        :id="id+'-input'"
        :type="inputType ?? 'text'"
        class="form-control"
        :placeholder="placeholder"
        aria-label="input"
        @focus="inputFocusEv"
        @blur="inputBlurEv"
        :value="sModelValue"
        @input="inputModelChange"
        @click="inputClickEv"
        :min="min"
        :max="max"
      >

      <div v-if="showRightIcon" class="right-icon-slot">
        <slot name="right-icon"></slot>
      </div>

      <button
        v-if="clearable && !hasRightIcon"
        @click.stop="clearInputText"
        class="btn btn-icon-only clear-btn"
      >
        <img
          src="@/assets/icons/close/default.svg"
          alt="clear icon"
        >
      </button>
    </div>

    <!-- Error -->
    <div
      v-if="errorMsg"
      class="
        ms-4
        mt-1
        input-error-container
      "
    >
      <img
        src="@/assets/icons/error/red.svg"
        alt="error svg"
      >
      <span class="ms-2">{{ errorMsg }}</span>
    </div>
  </div>
</template>
<script>
import {
  ref, watch, computed, onMounted,
} from 'vue'

export default {
  name: 'OmniBsInput',
  props: {
    id: {
      type: String,
      default: 'test-input',
    },
    inputType: String,
    min: Number,
    max: Number,
    showLeftIcon: Boolean,
    clearable: Boolean, // show the clear icon on the right
    modelValue: String, // input value
    errorMsg: String, // error message
    isDisabled: Boolean,
    placeholder: String,
    isSuccess: Boolean,
    hasError: Boolean, // show error state and error message
    label: String,
    maxLength: Number,
    softDisabled: Boolean,
    showRightIcon: Boolean,
  },
  setup(props, { emit, slots }) {
    const isInputActive = ref(false)
    const sModelValue = ref()

    /**
     * Clean input value
     */
    const clearInputText = () => {
      sModelValue.value = ''

      emit('clearInputTextEv', '')
    }

    /**
     * Emit an event to update the parent model value
     *
     * @param {Object} event - click event
     */
    const inputModelChange = (event) => {
      emit('update:modelValue', event.target.value)
    }

    const inputFocusEv = () => {
      isInputActive.value = true

      emit('inputFocusEv', '')
    }

    const inputBlurEv = () => {
      isInputActive.value = false
      emit('inputBlurEv', '')
    }

    const inputClickEv = () => {
      emit('inputClickEv', '')
    }

    // Check to see if the user using the right icon slot
    const hasRightIcon = computed(() => !!slots['right-icon'])

    // Update state when props change
    watch(() => props.modelValue, (value) => {
      sModelValue.value = value
    })

    onMounted(() => {
      sModelValue.value = props.modelValue
    })

    return {
      isInputActive,
      inputModelChange,
      clearInputText,
      inputFocusEv,
      inputBlurEv,
      inputClickEv,
      sModelValue,
      hasRightIcon,
    }
  },
}
</script>
<style scoped>
.right-icon-slot {
   margin-right: 0.8rem;
   display: flex;
}

.right-icon-slot ::v-deep(img), .clear-btn img {
   width: 1em;
}
</style>
