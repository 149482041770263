export default {
  oidc: {
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    issuer: process.env.VUE_APP_OKTA_ISSUER,
    redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URL,
    scopes: ['openid', 'profile', 'email'],
  },
  gis: {
    client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    // eslint-disable-next-line
    scope: 'https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adwords',
    access_type: 'offline',
    prompt: 'consent',
  },
}
