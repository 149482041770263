<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full pt-5">
      <div class="pair-content">
        <img
          class="ms-2 b center"
          src="@/assets/icons/error-grey.svg"
          alt="Error Icon"
          v-tooltip.bottom="`Query Logic:
          Join SEO & PPC Tables on ppc searchterm & seo query from the last 7 days and group the searchterms.
          This one's a little more complicated though as we need to look through all the organic pages and work out the best performing
            Organic Page... we can then check if it's an exact match to the PPC landing page(s) used.
            Before we show the results we add one more filter - we work out the account weighted quality score based on cost then show the
          records where searchterm quality score is less than the account weighted quality score. Oh then we show you the top 25 results.`"
        >
        <h1>Landing Page Test Opportunities</h1>
      </div>
      <p class="text-xs">Table showing top 25 Paid Landing Pages by clicks, with QS lower than weighted account QS</p>
      <div class="blurb">
        <p >Test Opportunity: Identify PPC keywords with low quality score and the corresponding organic landing pages ranking most highly.</p>
        <p>If the top performing organic page(s) differ from the paid landing page, this presents the opportunity to conduct a landing page test with the objective of improving Quality Score for paid terms.
        </p>
      </div>

      <button
        @click="loadPaidData(true)"
        class="bg-cyan-900 hover:bg-cyan-700 text-white py-1 px-2 mt-3 rounded"
      >
        {{ !isExportingData ? 'Export Table Data' : 'Downloading...'}}
      </button>

      <div v-if="chartLoaded" >
        <MetricsTable
          :tableData="tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData : tableData"
          :headerData=headerData
          :rowMap=rowMap
          @search-input-change="tableSearchInputEv"
          :showSearch="true"
          :searchPlaceholder="'Search in SearchTerm, Paid Landing Page(s), Optimal SEO Page'"
          :tableType="'searchLandingPageOpportunity'"
          :initialSortIndex="'3'"
          :isSortAscending="false"
        />
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable camelcase */
import {
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import MetricsTable from '@/components/charts//MetricsTable.vue'
import { downloadCSVWithResponseBlob } from '@/utils/helperFunctions'

const chartLoaded = ref(null)
const chartFilters = {
  lookback: 7,
  seo_rank: null,
}
const route = useRoute()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const headerData = [
  'SearchTerm',
  'Paid Qscore',
  'Paid Cost',
  'Paid Clicks',
  'Paid Landing Page(s)',
  'Optimal Org Page',
  'Org Page Rank',
  'Landing Page Match?',
]
const rowMap = [
  'searchterm',
  'paid_quality_score',
  'paid_cost',
  'paid_clicks',
  'paid_landingpage',
  'best_seo_page',
  'best_seo_position',
  'landingPageMatch',
]
const tableData = ref([])
const tableSearchText = ref('')
const tableSearchData = ref([])

const isExportingData = ref(false)

function loadPaidData(downloadAsCSV = false) {
  if (downloadAsCSV) {
    isExportingData.value = true
  }
  const seoRankParam = chartFilters.seo_rank ? `&seo_rank=${chartFilters.seo_rank}` : ''
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/landing_page_test_opportunity`

  const params = {
    client_id: route.params.id,
    lookback: chartFilters.lookback,
    seo_rank: seoRankParam,
    csv: downloadAsCSV,
  }
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL, { params })
    .then((response) => {
      if (downloadAsCSV) {
        downloadCSVWithResponseBlob('landing-page-test-opportunities', response.data)

        isExportingData.value = false
      } else {
        tableData.value = response.data
      }
    })
    .finally(() => {
      chartLoaded.value = true
    })
}

/**
 * handle table search event for:
 * - search term
 * - best seo page
 * - paid landing page
 *
 * @param {String} searchText - search text
 */
function tableSearchInputEv(searchText) {
  tableSearchText.value = searchText

  if (searchText !== '') {
    tableSearchData.value = tableData.value
      .filter((data) => data.searchterm.includes(searchText)
            || String(data.best_seo_page).includes(searchText)
            || String(data.paid_landingpage).includes(searchText))
  } else {
    tableSearchData.value = []
  }
}

onMounted(() => {
  loadPaidData()
})
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}
</style>
