<template>
  <div class="container mx-auto px-4">
    <h1 class="mt-5 mb-5">Google Analytics OAuth</h1>
    <router-link :to="{ name: 'gaAuthAdd' }">
      <button
        id="add-ga-oauth-link"
        class="
          bg-blue-500
          hover:bg-blue-600
          text-white
          py-2
          px-4
          rounded
          mt-1
          mb-5
        "
      >
        Add New GA OAuth
      </button>
    </router-link>
    <ga-auth-table></ga-auth-table>
  </div>
</template>

<script>
import GaAuthTable from '@/components/gaAuth/GaAuthTable.vue'

export default {
  name: 'GaAuthView',
  components: { GaAuthTable },
}
</script>
