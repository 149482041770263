<template>
  <div class="container mx-auto px-4">
    <h1 class="mt-5 mb-5">PPC Oauth</h1>
    <router-link :to="{ name: 'ppcAuthAdd' }">
      <button
        id="add-ppc-oauth-link"
        class="
          bg-blue-500
          hover:bg-blue-600
          text-white
          py-2
          px-4
          rounded
          mt-1
          mb-5
        "
      >
        Add New PPC OAuth
      </button>
    </router-link>
    <ppc-auth-table> </ppc-auth-table>
  </div>
</template>

<script>
import PpcAuthTable from '@/components/ppcAuth/PpcAuthTable.vue'

export default {
  name: 'PPCAuthView',
  components: { PpcAuthTable },
}
</script>
