<template>
  <div id='linechart'>
    <LineChart :chartData=chartData :options=options />

  </div>
</template>
<script>
import { defineComponent } from 'vue'
import { Chart, registerables } from 'chart.js'
import { LineChart } from 'vue-chart-3'

Chart.register(...registerables)
export default defineComponent({
  name: 'LineGraph',
  components: { LineChart },
  props: {
    chartData: Object,
    yAxisLabel: String,
    yAxisType: {
      type: String,
      default: 'logarithmic',
    },
  },
  setup(props) {
    const options = {
      scales: {
        y: {
          type: props.yAxisType,
          position: 'left',
          title: {
            display: true,
            text: props.yAxisLabel.toUpperCase(),
          },
        },
      },
    }
    return { LineChart, options }
  },
})
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#linechart{
  max-height: 450px;
}
</style>
