<!-- Omni Dropdown base on bootstrap -->
<!--
  -- Dropdown expect an array of object which has a text and value property
  -- for example {  }
  -- The dropdown contains multiple state by using the props below
    -- isLoading - loading state
    -- isDisabled - disabled state
    -- hasError - error state

  IMPORTANT - these props needed for the components to work
    -- options { Array } expect object array with text and value
    -- optionsValue { String } object key to get options value
    -- optionsText { String } object key to get options text

-->
<template>

  <div>
    <span class="input-label d-block mb-1 ml-0">{{ label }}</span>

    <div
      class="dropdown"
      :class="{ 'dropdown-type-menu': type === 'menu' }"
    >
      <!-- NORMAL DROPDOWN BUTTON -->
      <button
        :id="customLabel(id, '-options')"
        :ref="customLabel(id, '-ref')"
        :class="{ 'has-error': hasError, 'is-success': isSuccess }"
        class="btn dropdown-toggle d-flex align-items-center justify-content-between"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="true"
        @click="dropdownBtnClick"
        :disabled="isLoading || isDisabled"
        v-if="!customDropdownContentSlotIsSet"
      >
        <div :class="{ 'flex-grow-1': isLoading }" class="selected">
          <span :class="{ 'loader': isLoading , }">
            {{ !isLoading ? buttonText : '' }}
          </span>
        </div>

        <!-- chevron icon css -->
        <span class="chevron-icon"></span>
      </button>

      <!-- BUTTON ICON -->
      <button
        :id="customLabel(id, '-options')"
        :ref="customLabel(id, '-ref')"
        class="btn"
        type="button"
        data-bs-toggle="dropdown"
        data-bs-auto-close="true"
        aria-expanded="false"
        @click="dropdownBtnClick"
        :disabled="isLoading || isDisabled"
        v-if="customDropdownContentSlotIsSet"
      >
        <slot name="custom-dropdown-content"></slot>
      </button>

      <!-- error text -->
      <div
        v-if="hasError && errorText"
        class="hasError-container"
      >
        <img src="@/assets/icons/error/red.svg" alt="Error Icon">
        <span>{{ errorText }}</span>
      </div>

      <ul
        class="dropdown-menu"
        :aria-labelledby="customLabel(id, '-options')"
      >
        <li class="dropdown-item search" v-if="isSearchable">
          <img
            class="input-left-icon dropdown-search-icon"
            src="@/assets/icons/search.svg"
            alt="search icon"
          >
          <label
            :for="customLabel(id, '-search')"
            :id="customLabel(id, '-search-label')"
          >
            <input
              :name="customLabel(id, '-search')"
              type="text"
              class="search-input"
              placeholder="Search"
              v-model="searchQuery"
              @keyup="optionsFiltered()"
            />
          </label>
          <button
          @click.stop="clearInputText"
          class="btn btn-icon-only clear-btn search-input"
        >
          <img
          class="search-input"
            src="@/assets/icons/close/default.svg"
            alt="clear icon"
          >
        </button>
        </li>
        <li
          class="dropdown-item"
          v-for="(opt, index) in filteredOptions"
          :key="
            optionsValue
              ? opt[optionsValue]
              : JSON.stringify(opt[optionsValue]) + index
          "
          @click="selectedItem(opt)"
          @keydown="selectedItem(opt)"
        >
          <div class="option-left-content" v-if="type === 'dropdown'">
            <img
              v-if="buttonValue === opt[optionsValue]"
              src="@/assets/icons/check.svg" alt="check icon"
            >
          </div>
          <span class="option-text">{{ opt[optionsText] }}</span>
        </li>

        <!-- NO DATA -->
        <li
          class="justify-content-center mt-1 info-li"
          v-if="filteredOptions && filteredOptions.length === 0 && !isLoading"
        >
          <h6>No Data</h6>
        </li>

        <!-- LOADER FOR TYPE MENU -->
        <li
          class="justify-content-center mt-1"
          v-if="isLoading"
        >
          <span class="loader"></span>
        </li>
      </ul>
    </div>
  </div>
  </template>

<script setup>
import {
  ref, watch, useSlots, computed, onMounted,
} from 'vue'

const slots = useSlots()

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  options: Array,
  placeholder: {
    type: String,
    default: 'Placeholder Text',
  },
  optionsValue: String,
  optionsText: String,
  isSearchable: Boolean,
  isLoading: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  hasError: Boolean,
  errorText: String,
  isSuccess: Boolean,
  selectedOptions: String,
  label: String,
  selectedOption: Object,
  selectedOptionbyValue: String,
  type: {
    type: String,
    default: () => 'dropdown',
  },
})

const emit = defineEmits(['optionOnClick', 'dropdownMenuOpen'])

const searchQuery = ref('')
const buttonText = ref(props.placeholder)
const buttonValue = ref()
const filteredOptions = ref(props.options)
const isDropdownOpen = ref(false)

const customLabel = (x, txt) => x + txt

const selectedItem = (e) => {
  if (!Object.prototype.hasOwnProperty.call(e, 'notSelectable')) {
    buttonText.value = e[props.optionsText]
    buttonValue.value = e[props.optionsValue]
  }
  emit('optionOnClick', e)
}

const customDropdownContentSlotIsSet = computed(() => !!slots['custom-dropdown-content'])

// list for props change to update dropdown
watch(props, () => {
  filteredOptions.value = props.options
})

watch(() => props.selectedOption, (selectedOption) => {
  if (Object.keys(selectedOption).length === 0) return
  // eslint-disable-next-line no-console
  buttonText.value = selectedOption[props.optionsText]
  buttonValue.value = selectedOption[props.optionsValue]
})

watch(() => props.selectedOptionbyValue, (selectedOptionbyValue) => {
  if (props.selectedOptionbyValue) {
    const selectedOption = props.options.find((opt) => opt.value === selectedOptionbyValue)
    if (selectedOption && props?.optionsText && props?.optionsValue) {
      buttonText.value = selectedOption[props.optionsText]
      buttonValue.value = selectedOption[props.optionsValue]
    }
  }
})
onMounted(() => {
  if (props.selectedOption && Object.keys(props.selectedOption).length > 0) {
    buttonText.value = props.selectedOption[props.optionsText]
    buttonValue.value = props.selectedOption[props.optionsValue]
  }
})

// filter function when user search
const optionsFiltered = () => {
  // eslint-disable-next-line
        filteredOptions.value = props.options.filter((opt) => opt[props.optionsText]
    .toLowerCase()
    .includes(searchQuery.value.toLowerCase()))
}

const dropdownBtnClick = () => {
  isDropdownOpen.value = !isDropdownOpen.value
  emit('dropdownMenuOpen')
}

const clearInputText = () => {
  searchQuery.value = ''
  optionsFiltered()
}

</script>

  <style scoped>
  /* Dropdown */
  .dropdown {
    width: 100%;
    display: inline-block;
  }

  /* Has Error State */
  .dropdown .hasError-container {
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
  }

  .dropdown .hasError-container img {
    width: 1.2rem;
  }

  .dropdown .hasError-container span {
    margin-left: 0.3rem;
  }

  /* Display Button */
  button.dropdown-toggle {
    text-align: left !important;
    background-color: #f2f5fa !important;
    color: #3b3e3f !important;
    border: 0;
    border-radius: 6px;
    width: 100%;
    border: 1px solid #f2f5fa;
  }

  button.dropdown-toggle:focus,
  button.dropdown-toggle:active {
    outline: none !important;
    box-shadow: none;
    border-color: 0 0 0 1.2px #03bbf3;
    background-color: white !important;
  }

  button.dropdown-toggle img {
    width: 8%;
    margin-left: 0.5rem;
  }

  button.dropdown-toggle .loader {
    margin: auto;
  }

  button.dropdown-toggle.has-error {
    border-color: #df5678;
  }

  button.dropdown-toggle.is-success {
    border-color: #2CC4AD;
  }

  /* remove default arrow for dropdown and replace with new one */
  .dropdown-toggle::after{
    display: none;
  }

  .dropdown-toggle .chevron-icon {
    border-style: solid;
    content: '';
    display: inline-block;
    transform: rotate(135deg);
    width: 0.55rem;
    height: 0.55rem;
    margin-left: 1rem;
    border-top: 0.15rem solid black;
    border-right: 0.15rem solid black;
    border-bottom: 0;
    border-left: 0;
    margin-bottom: 0.24rem;
  }

  .dropdown-toggle.show .chevron-icon {
    transform: rotate(-45deg);
    vertical-align: 0.04rem;
    margin-top: 0.24rem;
    margin-bottom: 0;
  }

  /* Dropdown item container*/
  .dropdown-menu {
    --bs-dropdown-min-width: 15rem;
  }

  /*  Dropdown item */
  ul {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px 10px rgb(0 0 0 / 4%);
  }

  ul li:hover:not(.info-li) {
    background-color: #f0f2f5;
  }

  ul li.search:hover {
    background: transparent;
  }

  li {
    text-align: left !important;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  li .option-text {
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    margin-left: 0.5rem;
  }

  /* Search Input */
  .search {
    height:36px;
  }
  .search-input {
    height:100%;
    border:0;
  }
  .selected {
    overflow: hidden;
  }

  ul li.search-input:hover {
    margin-left: 0;
    background-color: #818181 !important;
  }

  .option-left-content {
    width: 9%;
  }

  .option-left-content img {
    width: 60%;
    margin-left: 0.3rem;
  }

  .dropdown-type-menu .dropdown-menu .dropdown-item .option-text {
    margin-left: 0;
  }

  .dropdown-type-menu .dropdown-menu .dropdown-item {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }

  ul li.search {
    padding: 0 8px;
    border-bottom: 1px solid #EDF0F4;
    margin-bottom: 0.5rem;
  }
  ul li.search .dropdown-search-icon {
    width: 6%;
    margin-right: 0.2rem;
  }

  ul li.search .btn-icon-only {
    padding: 0;
    justify-content: center;
  }
  ul li.search .btn-icon-only .search-input{
    width: 65%;
  }
  #campaign-search-label,  #campaign-search-label input{
    width: 100%;
  }

  #campaign-search-label,  #campaign-search-label input:focus-visible {
    outline: none;
  }

  </style>
