<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full pt-5">
      <div class="pair-content">
        <img
          class="ms-2 b center"
          src="@/assets/icons/error-grey.svg"
          alt="Error Icon"
          v-tooltip.bottom="`Query Logic:
          Join SEO & PPC Tables on ppc searchterm & seo query from the last 7 days and group the searchterms.
            We then filter the data to only show searchterms where the average maximum seo position is in the top 3...
              all the fields in the table below are then calculated.`"
        >
        <h1>
          Paid Brand Terms to pause | Number of searchterms :
          {{ tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData.length : tableData.length }}
        </h1>
      </div>
      <p class="text-xs">All Paid search terms with an Organic ranking between 1 and 3 (Initial load: 250 rows)</p>
      <div class="blurb">
        <p>Identify Brand Paid searchterms with low levels of competition, which also rank highly organically.</p>
        <p>Present an opportunity to pause such terms while traffic levels are maintained via organic results,
          ultimately driving overall cost savings.</p>
      </div>
      <br>

      <button
        @click="loadAllData"
        class="bg-cyan-900 hover:bg-cyan-700 text-white py-1 px-2 rounded"
      >
        Load All data
      </button>
      <button
        @click="loadPaidData(true)"
        class="bg-cyan-900 hover:bg-cyan-700 text-white mx-2 py-1 px-2 rounded"
      >
        {{ !isExportingData ? 'Export Table Data' : 'Downloading...'}}
      </button>

      <div v-if="chartLoaded">
        <MetricsTable
          :tableData="tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData : tableData"
          :headerData=headerData
          :rowMap=rowMap
          @search-input-change="tableSearchInputEv"
          :showSearch="true"
          :searchPlaceholder="'Search in SearchTerm'"
          :initialSortIndex="'1'"
        />
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable camelcase */
import {
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import MetricsTable from '@/components/charts//MetricsTable.vue'
import { downloadCSVWithResponseBlob } from '@/utils/helperFunctions'

const chartLoaded = ref(null)
const chartFilters = {
  lookback: 7,
  seo_rank: 3,
  num_records: 250,
}
const route = useRoute()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const headerData = [
  'SearchTerm',
  'Org Imps',
  'Org Clicks',
  'Org CTR',
  'Avg Rank',
  'Paid Imps',
  'Paid Clicks',
  'Paid CPC',
  'Paid CTR',
  'Paid Cost (£)',
]
const rowMap = [
  'searchterm',
  'organic_impressions',
  'organic_clicks',
  'organic_ctr',
  'organic_avg_rank',
  'paid_impressions',
  'paid_clicks',
  'paid_cpc',
  'paid_ctr',
  'paid_cost',
]
const tableData = ref([])
const tableSearchData = ref([])
const tableSearchText = ref('')
const isExportingData = ref(false)

function loadPaidData(downloadAsCSV) {
  if (downloadAsCSV) {
    isExportingData.value = true
  }
  const numberRecordsParam = chartFilters.num_records
    ? { num_records: chartFilters.num_records }
    : {}
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/brand_terms_to_pause`

  const params = {
    client_id: route.params.id,
    lookback: chartFilters.lookback,
    seo_rank: chartFilters.seo_rank,
    csv: downloadAsCSV,
    ...numberRecordsParam,
  }

  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL, { params })
    .then((response) => {
      if (downloadAsCSV) {
        downloadCSVWithResponseBlob('paid-brand-terms-to-pause', response.data)

        isExportingData.value = false
      } else {
        tableData.value = response.data
      }
    })
    .finally(() => {
      chartLoaded.value = true
    })
}

function loadAllData() {
  chartLoaded.value = false
  chartFilters.num_records = null
  loadPaidData(false)
}

/**
     * handle table search event
     *
     * @param {String} searchText - search text
     */
function tableSearchInputEv(searchText) {
  tableSearchText.value = searchText

  if (searchText !== '') {
    tableSearchData.value = tableData.value
      .filter((data) => data.searchterm.includes(searchText))
  } else {
    tableSearchData.value = []
  }
}

onMounted(() => {
  loadPaidData(false)
})
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}
.pair-content {
  display: grid;
  grid-template-columns: 0.02fr 1fr;
}
.center{
  margin: auto;
}

.tooltip {
  --bs-tooltip-max-width: 400px !important;
}

</style>
