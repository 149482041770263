<template>
  <div class="login">
    <router-link :to="{ name: 'home' }">
      <h1 class="px-3 py-3">Home</h1>
    </router-link>
    <div id="okta-signin-container"></div>
  </div>
</template>

<script>
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import sampleConfig from '@/config'

export default {
  name: 'LoginView',
  mounted() {
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      const {
        issuer, clientId, redirectUri, scopes, useInteractionCodeFlow,
      } = sampleConfig.oidc

      this.widget = new OktaSignIn({
        clientId,
        redirectUri,
        // eslint-disable-next-line global-require
        logo: require('@/assets/logo.png'),
        i18n: {
          en: {
            'primaryauth.title': 'Sign in to Search Connect',
          },
        },
        authParams: {
          issuer,
          scopes,
        },
        useInteractionCodeFlow,
      })

      const originalUri = this.$auth.getOriginalUri()
      if (!originalUri) {
        this.$auth.setOriginalUri('/')
      }

      this.widget.showSignInToGetTokens({
        el: '#okta-signin-container',
        scopes,
      }).then((tokens) => {
        this.$auth.handleLoginRedirect(tokens)
        const email = tokens.accessToken.claims.sub
        this.$store.commit('setUserEmail', email)
      })
    })
  },
  unmounted() {
    // Remove the widget from the DOM on path change
    this.widget.remove()
  },
}
</script>
