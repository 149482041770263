<script>
import { ref, onMounted, getCurrentInstance } from 'vue'
import axios from 'axios'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'

export default {
  components: {
    CheckIcon, CrossIcon,
  },
  name: 'GaAuthTable',
  props: {},
  methods: {
    routeToGaDetails(gaAuth) {
      this.$router.push({ name: 'gaAuthDetail', params: { id: gaAuth.id } })
    },
  },
  setup() {
    const gaAuths = ref([])
    const loading = ref(true)
    const apiError = ref(null)
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth

    function fetchData() {
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/ga_creds/`
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((response) => {
          gaAuths.value = response.data
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      gaAuths,
      loading,
      apiError,
      fetchData,
    }
  },
}
</script>
<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden shadow-md sm:rounded-lg">
          <table class="min-w-full">
            <thead class="bg-gray-100 dark:bg-gray-700">
              <tr class="border-b1">
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="
                    py-3
                    px-6
                    text-xs
                    font-medium
                    tracking-wider
                    text-left text-gray-700
                    dark:text-gray-400
                  "
                >
                  GA Token
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="gaAuth in gaAuths"
                :key="gaAuth.id"
                class="hover:bg-slate-50"
              >
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <button @click="routeToGaDetails(gaAuth)">
                    <span class="underline">{{ gaAuth.name }}</span>
                  </button>
                </td>
                <td
                  class="
                    py-4
                    px-6
                    text-sm text-gray-500
                    whitespace-nowrap
                    dark:text-gray-400
                  "
                >
                  <check-icon v-if="!!gaAuth.has_ga_token"/>
                  <cross-icon v-if="!gaAuth.has_ga_token"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>
