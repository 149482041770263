<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full mt-5">
      <h1>Organic Rank TimeSeries</h1>
      <div v-if="chartLoaded === true">
      <div class="w-full pt-10 px-5">
        <div class="mt-5 grid grid-cols-8 gap-1">
          <!-- Clicks Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="clicksmin" class="form-label inline-block mb-1 text-gray-700">Clicks Above</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="clicksmin"
                min="0"
                step="1"
              placeholder="Clicks Above"
              v-model="chartFilters.clicksMin"
            />
          </div>
          <!-- Clicks Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="clicksmax" class="form-label inline-block mb-1 text-gray-700">Clicks Below</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="clicksmax"
              placeholder="Clicks Below"
              v-model="chartFilters.clicksMax"
            />
          </div>
          <!-- Imps Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="impsmin" class="form-label inline-block mb-1 text-gray-700">Imps Above</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="impsmin"
                min="1"
                step="1"
              placeholder="QScore Above"
              v-model="chartFilters.impsMin"
            />
          </div>
          <!-- Imps Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="impsmax" class="form-label inline-block mb-1 text-gray-700">Imps Below</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="impsmax"
                min="1"
                step="1"
              placeholder="Imps Below"
              v-model="chartFilters.impsMax"
            />
          </div>
          <!-- Rank Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="rankmin" class="form-label inline-block mb-1 text-gray-700">Rank Above</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="rankmin"
                min="1"
              placeholder="Rank Above"
              v-model="chartFilters.rankMin"
            />
          </div>
          <!-- Rank Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="rankmax" class="form-label inline-block mb-1 text-gray-700">Rank Below</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="rankmax"
                min="1"
                step="1"
              placeholder="Rank Below"
              v-model="chartFilters.rankMax"
            />
          </div>
          <div class="mb-3"></div>
          <!-- Apply Filters Button -->
          <div class="mb-3">
            <div>
              <button
              class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded"
              @click="filterTimeline()">Filter</button>
            </div>
          </div>
        </div>
        <div class="mt-5 grid grid-cols-8 gap-1">
          <!-- Lookback Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="lookback" class="form-label inline-block mb-1 text-gray-700">Lookback Window</label
            >
            <div>
              <v-select
                v-model="chartFilters.lookback"
                :options="lookbackOptions"
                :reduce="lookback => lookback.value"
                @update:modelValue="filterTimeline()"
                aria-label="Look Back Dropdown"
                :searchable="false"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                    @focus="showTruncatedTextWithTitle(option.label, $event)"
                  >{{ option.label }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      <div class=" mt-10">
        <h4 v-if="chartFilters.selectedKeyword" >Filter | {{ chartFilters.selectedKeyword }}</h4>
        <LineGraph :chartData=chartData :yAxisLabel=chartFilters.metric :yAxisType=chartData.yAxisType />
      </div>
      </div>
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data...</h1>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { ref, getCurrentInstance, onMounted } from 'vue'
import LineGraph from '@/components/charts/LineGraph.vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import vSelect from 'vue-select'

export default {
  name: 'OrganicRankingTimeSeries',
  components: { LineGraph, SideNav, vSelect },
  setup() {
    const organicData = []
    const chartLoaded = ref(false)
    const chartFilters = {
      metric: 'Avg Rank',
      clicksMin: 0,
      clicksMax: null,
      impsMin: 0,
      impsMax: null,
      rankMin: 0,
      rankMax: 100,
      lookback: 30,
      queries: [],
    }
    const route = useRoute()
    const clientId = route.params.id
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const chartData = {
      labels: [],
      datasets: [],
      yAxisType: 'linear',
    }
    const lookbackOptions = [
      { label: '7 Day view', value: 7 },
      { label: '30 Day view', value: 30 },
      { label: '60 Day view', value: 60 },
      { label: '90 Day view', value: 90 },
    ]
    // The tailwind 400 colour hexs
    const lineColours = [
      '#a8a29e',
      '#f87171',
      '#a3e635',
      '#4ade80',
      '#34d399',
      '#2dd4bf',
      '#22d3ee',
      '#38bdf8',
      '#60a5fa',
      '#818cf8',
      '#a78bfa',
      '#c084fc',
      '#e879f9',
      '#f472b6',
      '#fb7185',
      '#78350f',
      '#fbbf24',
      '#fb923c']
    function loadOrganicData() {
      const lookbackParam = chartFilters.lookback ? `&lookback=${chartFilters.lookback}` : ''
      const clicksMinParam = chartFilters.clicksMin ? `&cicks_min=${chartFilters.clicksMin}` : ''
      const clicksMaxParam = chartFilters.clicksMax ? `&cicks_max=${chartFilters.clicksMax}` : ''
      const impsMinParam = chartFilters.impsMin ? `&imps_min=${chartFilters.impsMin}` : ''
      const impMaxParam = chartFilters.impsMax ? `&imps_max=${chartFilters.impsMax}` : ''
      const rankMinParam = chartFilters.rankMin ? `&rank_min=${chartFilters.rankMin}` : ''
      const rankMaxParam = chartFilters.rankMax ? `&rank_max=${chartFilters.rankMax}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/organic_rank_timeseries?client_id=${route.params.id}
      ${lookbackParam}
      ${clicksMinParam}
      ${clicksMaxParam}
      ${impsMinParam}
      ${impMaxParam}${rankMinParam}${rankMaxParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          chartData.labels.length = 0
          chartData.datasets.length = 0
          organicData.length = 0
          const dataArray = data.data
          dataArray.forEach((d) => {
            chartData.labels = d.query_date
            chartData.datasets.push({
              label: d.seo_query,
              data: d.max_rank,
              borderColor: lineColours[dataArray.indexOf(d)],
              backgroundColor: lineColours[dataArray.indexOf(d)],
              pointColor: lineColours[dataArray.indexOf(d)],
            })
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value = true
        })
    }
    function filterTimeline() {
      organicData.length = 0
      chartLoaded.value = false
      organicData.length = 0
      chartData.labels.length = 0
      chartData.datasets.length = 0
      loadOrganicData()
    }
    function clearFilters() {
      organicData.length = 0
      chartLoaded.value = false
      chartFilters.lookback = 30
      chartFilters.queries.length = 0
      chartFilters.selectedKeyword = null
      loadOrganicData()
    }

    onMounted(() => {
      loadOrganicData()
    })
    return {
      clientId,
      LineGraph,
      chartData,
      organicData,
      chartLoaded,
      loadOrganicData,
      chartFilters,
      clearFilters,
      filterTimeline,
      lookbackOptions,
    }
  },
  methods: {
  },
}
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}

</style>
