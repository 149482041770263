<template>
  <div class="table-container bg-white">
    <div class="table-title d-flex align-items-center align-content-center justify-content-between" v-if="showSearch">
      <span class="table-title-text"></span>
      <OmniBsInput
        id="table-title-search"
        :modelValue="searchText"
        :clearable="true"
        :showLeftIcon="true"
        :placeholder="'Search Entry'"
        @update:modelValue="searchInputChange"
        @clearInputTextEv="clearSearch"
        @inputFocusEv="() => searchIsActive = true"
        @inputBlurEv="() => searchIsActive = false"
      >
        <template #left-icon>
            <img
              v-if="!searchIsActive"
              class="input-left-icon dropdown-search-icon"
              src="@/assets/icons/search.svg"
              alt="search icon"
            >
            <img
              v-if="searchIsActive"
              class="input-left-icon dropdown-search-icon"
              src="@/assets/icons/search-blue.svg"
              alt="search icon"
            >
        </template>
      </OmniBsInput>
    </div>
    <div class="table-container-content">
      <table
        class="table-auto rounded text-left rounded-lg divide-y mt-3"
      >
        <thead class="table-header">
            <tr>
              <th
                scope="col"
                v-for="(header, index) in headerData"
                :key="index"
                class="table__header text-left rounded-sm"
                @click="tableSortToggle(index)"
              >
                <div class="table__header__content">
                  <p class="inline-block">
                    {{ header }}
                    <!-- eslint-disable-next-line -->
                    <img
                      :alt="
                        'chevron'
                        + state.isSortAsc && index === state.sortedRowIndex ? 'up' : 'down'
                        + 'icon'
                      "
                      :class="{ chevronUp: state.isSortAsc && index === state.sortedRowIndex }"
                      class="inline"
                      src="@/assets/icons/chevron-down.svg"
                    />
                  </p>
                </div>
              </th>
            </tr>
        </thead>
        <tbody
          v-for="row in initialTableData"
          :key="row.id"
          class="table-body"
        >
          <tr
            class="align-items-center table-row-normal table-row-remove-hover"
          >
              <td
                class="px-2 py-2 text-sm"
                v-for="map in rowMap"
                :key="map.id"
              >
                <a
                  v-if="tableType === 'searchLandingPageOpportunity' &&
                    (map === 'paid_landingpage' || map === 'best_seo_page')"
                  :href="row[map]"
                  class="text-blue-700"
                >
                  {{ row[map] }}
                </a>
                <span
                  v-else
                  :class="{
                    'text-rose-600': map === 'landingPageMatch' && row[map] === 'DIFFERENT',
                    'text-green-600': map === 'landingPageMatch' && row[map] === 'MATCHED'
                  }"
                >
                  {{ row[map] }}
                </span>
              </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-10 text-center" v-if="initialTableData.length === 0">
        <h1 class="mx-auto">{{ searchText !== '' ? 'No Search Result' : 'No Data' }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted, reactive, ref, watch,
} from 'vue'
import OmniBsInput from '@/components/Omni/OmniBsInput.vue'

export default {
  name: 'MetricsTable',
  components: { OmniBsInput },
  props: {
    tableData: Array,
    headerData: Object,
    rowMap: Array,
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    tableType: {
      type: String,
      default: '',
    },
    initialSortIndex: {
      type: String,
      default: '2',
    },
    isSortAscending: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // setup() receives props as the first argument.
    const state = reactive({
      sortedRowIndex: null,
      isSortAsc: props.isSortAscending,
      originalTableData: props.tableData,
      isClearSearchPress: false,
      index: props.initialSortIndex,
    })

    const searchText = ref(null)
    const searchIsActive = ref(false)
    const initialTableData = ref(props.tableData)

    /* eslint-disable */
    /**
     * Sort row asc or desc and if reset flag is set then the
     * sort will remain desc
     *
     * @param {String} index - position of header
     * @param {Boolean} reset - table reset flag
     */
    function tableSortToggle(index, reset = false) {
      if (initialTableData.value.length === 0) return
      const rowName = props.rowMap[index]

      // handle the state of the asc and desc icon
      if (state.sortedRowIndex === index) {
        state.isSortAsc = !state.isSortAsc
      } else {
        state.sortedRowIndex = index
        state.isSortAsc = state.isSortAsc
      }

      if (reset) {
        state.isSortAsc = state.isSortAsc
      }

      const dataType = typeof initialTableData.value[0][rowName]
      initialTableData.value = sortData(dataType, rowName)
    }

    /**
     * handle sorting data from table that passed through
     *
     * @param {Array} dataType - type of data in the column
     * @param {String} rowName
     */
    function sortData(dataType, rowName) {
      let sortData = []

      if (dataType === 'number') {
        sortData = initialTableData.value.sort((a,b) =>
          state.isSortAsc
            ? a[rowName] - b[rowName]
            : b[rowName] - a[rowName]
        )
      } else {
        sortData = initialTableData.value.sort((a,b) =>
          state.isSortAsc
            ? (a[rowName] > b[rowName]) - (a[rowName] < b[rowName])
            : (a[rowName] < b[rowName]) - (a[rowName] > b[rowName])
        )
      }

      return sortData
    }

    /**
     * handle search input, input event
     *
     * @param {Object} event - event object
     */
    function searchInputChange(event) {
      searchText.value = event
      emit('searchInputChange', event)
    }

    // update table data when props is update
    watch(() => props.tableData, (newValue, oldValue) => {
      initialTableData.value = newValue

      if (state.isClearSearchPress) {
        tableSortToggle(0, true)
        state.isClearSearchPress = false
      }
    });

    /**
     * clear search input when the user click/press enter on close icon
     *
     * @param {Object} event
     */
    function clearSearch(event) {
      searchText.value = ''
      emit('searchInputChange', '')
      state.isClearSearchPress = true
    }

    // on mount will default sort the table asc on first column
    onMounted(() => {
      tableSortToggle(state.index, true)
    })

    return {
      state,
      tableSortToggle,
      searchInputChange,
      searchText,
      searchIsActive,
      clearSearch,
      initialTableData,
      OmniBsInput
    }
},
}
</script>

<style scoped>
table thead th:nth-of-type(1){
  width: 30%;
}

.chevronUp {
  transform: rotate(180deg);
}

.dropdown-search-icon {
  width: 1.4em;
}

.table-container {
  border-radius: 10px;
  box-shadow: 0px 10px 60px rgb(1 33 78 / 10%);
  opacity: 1;
  background-color: rgba(245,248,251, 1) !important;
  padding: 2.0em 1.3em 0.5em 1.3em;
  /* height: 100%; */
  overflow: auto;
  height: 100vh;
}
.table-container{
  background-color: #F5F8FB;
  box-shadow: none;
  overflow: auto;
}

.table-container-content {
  height: 90%;
  overflow: auto;
}

.table-title {
  border-radius: 10px 10px 0 0;
  padding: 0.2em 1em;
  background-color: white;
  height: 2.7em;
  width: 100%;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #F5F8FB;
}

.table-readonly tbody tr {
  pointer-events: none;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-header th {
  background-color: #F5F8FB;
  padding-bottom: 0.8em;
}

.table-header th:first-child {
  padding-left: 2em;
}

.table.left-icon-design th:first-child {
  padding-left: 3.9em;
}

/* table header content */
.table-header th div {
  display: flex;
  font-size: 1.04rem;
  color: #3B3E3F;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  align-items: end;
  height: 100%;
}

.table-header th div span {
  text-align: left;
}

.table-header th div img {
  width: 1em;
  margin-left: 0.4em;
  margin-bottom: 0.25em;
}

.table-body tr.table-row-normal {
  cursor: pointer;
  background-color: white;
  border-top-left-radius: 10px;
  min-height: 4.5em;
  border-radius: 6px;
}

.table-body tr.table-row-normal:hover {
  position: relative;
  background-color: white;
  box-shadow: 0px 17px 45px -22px #01214e;
  -webkit-box-shadow: 0px 17px 45px -22px #01214e;
  -moz-box-shadow: 0px 17px 45px -22px #01214e;
  cursor: pointer;
}

.table-body tr.table-row-remove-hover:hover {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  cursor: default !important;
}

/* SPACING BETWEEN ROW */
tbody.table-body:before {
    content: '';
    display: block;
    height: 1rem;
}
/**********************/
.table tr.table-row-normal td:first-child {
  padding-left: 2em;
  position: relative;
}

.table.left-icon-design .table-body tr.table-row-normal td:first-child {
  padding: 0;
  display: flex;
  align-items: center;
}

.table.left-icon-design .table-body tr.table-row-normal td:first-child :deep(img){
  padding: 0 1em;
  width: 3.5rem;
}

.table.left-icon-design-with-border .table-body tr.table-row-normal {
  position: relative;
}

.table.left-icon-design-with-border .table-body tr.table-row-normal::before {
  content: "";
  border-left: 2px solid #1DA1D2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.table-body tr.table-row-normal:hover td:first-child {
  color: #00A1D2;
}

.table-body tr.table-row-remove-hover:hover td:first-child {
  color: #3B3E3F;
}

/* DISABLED STATE */
.table-body .table-row-disabled {
  color: #A2A9AD;
  opacity: 0.6;
}

.table-body .table-row-disabled:hover {
  box-shadow: none !important;
  cursor: default !important;
}

.table-body .table-row-disabled:hover td:first-child {
  color: #A2A9AD;
}

/* EXPANDABLE ROW */
.table-body tr.table-row-expandable:hover {
  color: #00a1d2;
  box-shadow: none;
}
.table-body tr.table-row-expandable-content {
  margin-bottom: 1rem;
  height: 0;
  background-color: white;
}

.table-body tr.table-row-expandable-content td {
  border-top: 1px solid #F3F3F3;
  border-radius: 0;
}

.table-body tr.table-row-expandable-content--open {
  height: auto;
}

/* ACTIVE STATE */
.table-body .table-row-active {
  background: linear-gradient(90deg, rgba(1, 161, 210, 0.15) 0%, rgba(3, 187, 243, 0.15) 100%);
}

.table-body .table-row-active:hover{
  cursor: default;
  box-shadow: none;
  background: linear-gradient(90deg, rgba(1, 161, 210, 0.15) 0%, rgba(3, 187, 243, 0.15) 100%);
}

.table-body td:first-child,
.table-body th:first-child {
  border-radius: 6px 0 0 6px;
}

.table-body td:last-child,
.table-body th:last-child {
  border-radius: 0 6px 6px 0;
}

.d-table-caption {
  display: table-caption;
}

.table-data-state {
  height: 20em;
  background-color: white;
}

.table-data-state-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.table-data-state-content {
  color: #3B3E3F;
}

.btn-show-more {
  background-color: #F1F5FA;
  font-size: 0.8rem;
  font-weight: 400;
  color: #3B3E3F;
}

.text-color-primary {
  color: #00a1d2;
}

.table-row-error td, .table-row-error div{
  color: #F95D6A;
}
</style>
