<template>
  <div>
    <a
      class="oauth-link"
      @click="getClientAuthUrl"
      @keypress="getClientAuthUrl"
    >
      create a shareable OAuth link.
    </a>
  </div>
</template>
<script setup>
import { getCurrentInstance } from 'vue'
import axios from 'axios'

const props = defineProps({
  clientId: Number,
  gAuthType: String,
})
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const emits = defineEmits(['authUrlFetched'])

async function getClientAuthUrl() {
  if (!props.clientId) {
    return false
  }

  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/${props.clientId}/signed_url?scope=${props.gAuthType}`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL)
    .then((response) => {
      const authUrl = `${window.location.origin}/clients/${props.clientId}/signed-url/${encodeURIComponent(response.data)}/${props.gAuthType}`
      emits('authUrlFetched', authUrl)
    })
    .catch((error) => {
      // eslint-disable-next-line
          console.log(error)
    })
    .finally()
  return true
}
</script>
<style scoped>
.oauth-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
