<template>
  <div>
    <omni-bs-dropdown
      :id="'clientDropdown'"
      :options="userClient"
      placeholder="Select Client"
      optionsText="label"
      optionsValue="value"
      @optionOnClick="clientDropdownClick"
      :isLoading="clientIsFetching"
    >
    </omni-bs-dropdown>
  </div>
</template>
<script setup>
import OmniBsDropdown from '@/components/Omni/OmniBsDropdown.vue'
import { getCurrentInstance, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'

const store = useStore()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth

const clientIsFetching = computed(() => store.getters.userClientIsFetch)

const userClient = computed(() => {
  const clientList = store.state.clients.all
  const clientListObjArr = clientList.map((client) => ({ value: client.id, label: client.name }))
  return clientListObjArr
})

onMounted(() => {
  if (!store.state.clients.all.length) {
    store.dispatch('getUserClients', oktaAuth.getAccessToken())
  }
})

function clientDropdownClick(event) {
  router.push(
    {
      name: 'clientDashboard',
      params: { id: event.value, name: event.label },
    },
  )
}

// async mounted() {
//   const windowPath = window.location.pathname

//   // This is set the selected Client Dashboard
//   // if the user enters client dashboard URL the directly in the browser
//   if (windowPath.includes('dashboard') && !this.selectedDashboard) {
//     this.selectedDashboard = this.$route.params.id
//     const clientId = windowPath.replace(/\D/g, '')
//     this.selectedDashboard = clientId
//   } else {
//     this.selectedDashboard = ''
//   }
// },
</script>
