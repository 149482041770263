<template>
  <div class="container mx-auto px-4">
    <p class="text-2xl dark:text-white p-t20 my-3">
      Google Analytics OAuth: {{ gaAuth ? gaAuth.name : "" }}
    </p>
    <google-o-auth-button
      :entityId="Number(gaAuthIdFromRoute)"
      :gAuthType="'GA'"
      @token-added="tokenAddedEvent"
      class="mt-5 mb-5"
    ></google-o-auth-button>
    <br />
    <ga-auth-url-button
      class="mt-5 mb-5"
      :gaId="Number(gaAuthIdFromRoute)"
      :gAuthType="'GA'"
      @auth-url-fetched="gaAuthUrlFetched"
    ></ga-auth-url-button>
    <div v-if="fetchedUrl">
      <div class="text-right">
        <button class="mb-0 mt-5" @click="copySignedUrl">
          <copy-icon />
        </button>
      </div>
      <div
        class="
          mt-0
          p-4
          mb-4
          text-sm text-green-700
          bg-green-100
          rounded-lg
          dark:bg-green-200 dark:text-green-800
          break-all
        "
        role="alert"
        ref="signedUrl"
      >
        {{ fetchedUrl }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import GoogleOAuthButton from '@/components/gOauth/GoogleOAuthButton.vue'
import CopyIcon from '@/components/icons/CopyIcon.vue'
import GaAuthUrlButton from '@/components/gaAuth/GaAuthUrlButton.vue'

defineProps({
  clientId: Number,
})

const route = useRoute()
const router = useRouter()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const gaAuth = ref(null)
const loading = ref()
const gaAuthIdFromRoute = ref(route.params.id)
const fetchedUrl = ref(null)
const authUrl = ref(null)

function copySignedUrl() {
  navigator.clipboard.writeText(fetchedUrl.value)
}

function gaAuthUrlFetched(authUrlValue) {
  fetchedUrl.value = authUrlValue
}

function tokenAddedEvent() {
  router.push({ name: 'clients' })
}

function fetchGaAuth(gaAuthId) {
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/ga_creds/${gaAuthId}`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL)
    .then((response) => {
      gaAuth.value = response.data
      fetchedUrl.value = null
      authUrl.value = ''
    })
    .finally(() => {
      loading.value = false
    })
}
onMounted(() => {
  fetchGaAuth(route.params.id)
  gaAuthIdFromRoute.value = route.params.id
})
</script>
