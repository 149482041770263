<template>
  <div class="container mx-auto px-4">
    <form class="w-full max-w-sm mt-10">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-2/3">
          <label
            for="ppc-auth-name"
            class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
          >
            PPC OAuth Name:
            <input
              class="
                bg-gray-200
                appearance-none
                border-2 border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-purple-500
              "
              id="ppc-auth-name"
              type="text"
              v-model="ppcAuthName"
              ari-label="PPC Auth name"
            />
          </label>

        </div>
      </div>

      <div class="md:flex md:items-center">

        <div class="md:w-2/3">
          <button
            class="
              shadow
              bg-purple-500
              hover:bg-purple-400
              focus:shadow-outline focus:outline-none
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
            type="button"
            @click="addPpcAuth"
          >
            Add PPC OAuth
          </button>
        </div>
      </div>
    </form>
    <div
      v-if="ppcAddError || ppcValidationError"
      class="
        bg-red-100
        border border-red-400
        text-red-700
        px-4
        py-3
        rounded
        relative
        w-full
        mt-5
      "
      role="alert"
    >
      <span class="block sm:inline ml-2">{{ errorMessage }}.</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
        <svg
          class="fill-current h-6 w-6 text-red-500"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <!-- eslint-disable -->
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
          <!-- eslint-enable -->
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      ppcAuthName: '',
      ppcAuthAdded: false,
      ppcAddError: false,
      ppcValidationError: false,
      errorMessage: '',
    }
  },
  methods: {
    addPpcAuth() {
      if (!this.validatePpcAuth()) {
        return false
      }
      this.ppcAuthAdded = false
      this.ppcAddError = false
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/ppc_creds/`
      axios.defaults.headers.common.Authorization = `Bearer ${this.$auth.getAccessToken()}`
      axios
        .post(API_URL, { name: this.ppcAuthName })
        .then(() => {
          this.ppcAuthAdded = true
          this.$router.push({ name: 'ppcAuth' })
        })
        .catch(() => {
          this.ppcAddError = true
          this.errorMessage = 'Error creating PPC OAuth'
        })
      return true
    },
    validatePpcAuth() {
      let validated = true
      if (!this.ppcAuthName) {
        this.ppcValidationError = true
        this.errorMessage = 'PPC OAuth name cannot be empty'
        validated = false
      }

      return validated
    },
  },
}
</script>
