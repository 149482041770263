<template>
  <div class="container mt-10 px-4 md:w-2/3 mx-auto">
    <h1>SearchConnect uses Google’s API</h1><br>

    <p>SearchConnect uses Google’s Application Programming Interface (API) Services to enable the user authorization feature for accessing data.
    SearchConnect's use and transfer to any other app of information received from Google APIs will adhere to the
     <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Service User Data Policy</a>,
    including the <a href="https://support.google.com/cloud/answer/9110914#explain-types">Limited Use Requirements.</a></p><br>

    <p>SearchConnect's policies and procedures define requirements that prohibit the unauthorized use of data within the platform.</p><br>

    <p>We place significant importance on maintaining the security and privacy of your data.
      Reference our
       <router-link :to="{name: 'privacyPolicy'}">
        Privacy Policy
      </router-link> for information on our privacy policies regarding the use of the SearchConnect platform and its services.</p><br>
  </div>
</template>
<style scoped>
a{
  font-weight: bold;
  text-decoration: underline;
}
</style>
