import axios from 'axios'

export default (router) => {
  axios.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 403) {
      router.push({ name: 'forbidden' })
    }
    return Promise.reject(error)
  })
}
