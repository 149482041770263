<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full pt-5">
        <h1>Paid TimeSeries</h1>
        <div class="blurb">
          <p>
            Filter to specific search terms or view an account as a whole to identify Paid trends over time,
            whether this be for macro strategic purposes or to analyse the impact of any tests being conducted.
          </p>
        </div>
      <Disclosure defaultOpen=true>
      <div class="line">
        <DisclosureButton class="button">
          <img src="@/assets/icons/filter.svg" alt="filter"/> Filter
        </DisclosureButton>
      </div>
      <div v-if="chartLoaded > 1">
        <div class="w-full pt-10 px-5">
          <!-- Account & Campaign Filters -->
          <DisclosurePanel>
          <div class="grid grid-cols-5 pb-2 gap-2">
            <!-- Ad Account Selector -->
            <div>
              <v-select v-model="chartFilters.selectedAdAccount"
                :options="adAccounts"
                :reduce="account => account.adAccountId"
                label="adAccountName"
                aria-label="Select Ad Account"
                @update:modelValue="filterTimeline('adaccount')"
                placeholder="Select Ad Account"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.adAccountName, $event)"
                    @focus="showTruncatedTextWithTitle(option.adAccountName, $event)"
                  >{{ option.adAccountName }}</span>
                </template>
              </v-select>
            </div>
            <!-- Campaign Selector -->
            <div>
              <v-select
                v-model="chartFilters.selectedCampaign"
                :options="campaigns"
                :reduce="campaign => campaign.campaignId"
                label="campaignName"
                @update:modelValue="filterTimeline()"
                placeholder="Select Campaign"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.campaignName, $event)"
                    @focus="showTruncatedTextWithTitle(option.campaignName, $event)"
                  >{{ option.campaignName }}</span>
                </template>
              </v-select>
            </div>

            <!-- Paid SearchTerm Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.selectedKeyword"
                :options="searchTerms"
                label="index"
                @update:modelValue="filterTimeline()"
                placeholder="Select Search Term"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.index, $event)"
                    @focus="showTruncatedTextWithTitle(option.index, $event)"
                  >{{ option.index }}</span>
                </template>
              </v-select>
            </div>
            <!-- Lookback Selector -->
            <div class="w-5/6">
              <v-select v-model="chartFilters.lookback"
              :options="lookbackOptions"
              :reduce="lookback => lookback.value"
              aria-label="Lookback Dropdown Filter"
              @update:modelValue="filterTimeline()"
              placeholder="Select Search Term"
              :searchable="false"
              :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                    @focus="showTruncatedTextWithTitle(option.label, $event)"
                  >{{ option.label }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <div class="mt-5 grid grid-cols-8 gap-1">
            <!-- Metric Selector -->
            <div>
              <!-- eslint-disable-next-line -->
              <label for="ctrmin" class="form-label inline-block mb-1 text-gray-700">Metric</label>
              <!-- Metrics Selector -->
              <div>
                <v-select
                  v-model="chartFilters.metric"
                  :options="metricOptions"
                  :reduce="metric => metric.value"
                  @update:modelValue="filterTimeline()"
                  aria-label="Metric Dropdown Filter"
                  :searchable="false"
                  :clearable="false"
                >
                  <template v-slot:option="option">
                    <span
                      @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                      @focus="showTruncatedTextWithTitle(option.label, $event)"
                    >{{ option.label }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <!-- CTR Above Selector -->
            <div class="mb-3">
              <!-- eslint-disable-next-line -->
              <label for="ctrmin" class="form-label inline-block mb-1 text-gray-700">CTR Above</label
              >
              <input
                type="number"
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="ctrmin"
                  min="0"
                  max="1"
                  step="0.1"
                placeholder="CTR Above"
                v-model="chartFilters.ctrMin"
              />
            </div>
            <!-- CTR Below Selector -->
            <div class="mb-3">
              <!-- eslint-disable-next-line -->
              <label for="ctrmax" class="form-label inline-block mb-1 text-gray-700">CTR Below</label
              >
              <input
                type="number"
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="ctrmax"
                  min="0"
                  max="1"
                  step="0.1"
                placeholder="CTR Below"
                v-model="chartFilters.ctrMax"
              />
            </div>
            <!-- QScore Above Selector -->
            <div class="mb-3">
              <!-- eslint-disable-next-line -->
              <label for="qscoremin" class="form-label inline-block mb-1 text-gray-700">QScore Above</label
              >
              <input
                type="number"
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="qscoremin"
                  min="0"
                  max="10"
                  step="1"
                placeholder="QScore Above"
                v-model="chartFilters.qscoreMin"
              />
            </div>
            <!-- QScore Below Selector -->
            <div class="mb-3">
              <!-- eslint-disable-next-line -->
              <label for="qscoremax" class="form-label inline-block mb-1 text-gray-700">QScore Below</label
              >
              <input
                type="number"
                class="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-700
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="qscoremax"
                  min="0"
                  max="10"
                  step="1"
                placeholder="QScore Below"
                v-model="chartFilters.qscoreMax"
              />
            </div>

            <div class="mb-3"></div>
            <div class="mb-3"></div>
            <div class="mb-3"></div>
            <!-- Apply Filters Button -->
            <div class="mb-3">
              <div class="relative">
                <button
                class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded absolute right-0"
                @click="filterTimeline()">Filter</button>
              </div>
            </div>
          </div>
          </DisclosurePanel>
        </div>
        <div class="mt-10">
            <div class="my-5">
              <span v-if="chartFilters.metric" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Metric | {{ chartFilters.metric }}</span>
              <span v-if="chartFilters.lookback" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Lookback | {{ chartFilters.lookback + ' days' }}</span>
              <span v-if="chartFilters.selectedKeyword" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Searchterm | {{ chartFilters.selectedKeyword }}</span>
              <span v-if="chartFilters.selectedCampaign" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Campaign | {{ chartFilters.selectedCampaignName }}</span>
              <span v-if="chartFilters.selectedAdAccount" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">AdAccount | {{ chartFilters.selectedAdAccountName }}</span>
              <span v-if="chartFilters.qscoreMin" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Min QScore | {{ chartFilters.qscoreMin }}</span>
              <span v-if="chartFilters.qscoreMax" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Max QScore | {{ chartFilters.qscoreMax }}</span>
              <span v-if="chartFilters.ctrMin" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Min QScore | {{ chartFilters.ctrMin }}</span>
              <span v-if="chartFilters.ctrMax" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Max QScore | {{ chartFilters.ctrMax }}</span>
            </div>
          <div v-if="chartLoaded == 2 ">
            <h4 v-if="chartFilters.selectedKeyword" >Filter | {{ chartFilters.selectedKeyword }}</h4>
            <LineGraph :chartData=chartData :yAxisLabel=chartFilters.metric />
          </div>
        </div>
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
      </Disclosure>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import {
  ref, getCurrentInstance, onMounted, computed,
} from 'vue'
import LineGraph from '@/components/charts/LineGraph.vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import useShowTruncatedText from '@/composable/useShowTruncatedText'
import { useStore } from 'vuex'

export default {
  name: 'SearchTimeSeriesDashboard',
  components: {
    LineGraph, SideNav, vSelect, Disclosure, DisclosureButton, DisclosurePanel,
  },
  setup() {
    const store = useStore()
    const paidData = []
    const chartLoaded = ref(0)
    const lookbackOptions = [
      { label: '7 Day View', value: '7' },
      { label: '30 Day view', value: '30' },
      { label: '60 Day view', value: '60' },
      { label: '90 Day View', value: '90' },
    ]
    const chartFilters = ref({
      metric: 'impressions',
      lookback: 30,
      selectedKeyword: null,
      ctrMin: 0,
      ctrMax: 1,
      qscoreMin: 0,
      qscoreMax: 10,
      selectedCampaign: null,
      selectedCampaignName: null,
      selectedAdAccount: null,
      selectedAdAccountName: null,
      numberTerms: 1000,
    })
    const { showTruncatedTextWithTitle } = useShowTruncatedText()
    // Dropdown variables
    const metricOptions = [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'CTR', value: 'ctr' },
      { label: 'Cost', value: 'cost' },
      { label: 'CPC', value: 'cpc' },
      { label: 'QScore', value: 'quality_score' },
      { label: 'Weighted QScore', value: 'wqs' },
      { label: 'Abs Top Imp %', value: 'abs_top_imp_percentage' },
      { label: 'Abs Top Imp Share', value: 'abs_top_imp_share' },
    ]
    const route = useRoute()
    const clientId = route.params.id
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const dataLabels = []
    const searchTerms = []
    const chartData = {
      labels: dataLabels,
      datasets: [{
        label: 'Paid Search TimeSeries',
        data: paidData,
        borderColor: 'rgb(14, 165, 233)',
      },
      ],
    }

    const adAccounts = computed(() => store.getters.clientAdAccounts(clientId))
    const campaigns = computed(() => store.getters.adAccountCampaigns(chartFilters.value.selectedAdAccount))

    function loadPaidData() {
      const metricMap = {
        impressions: 'paid_impressions',
        clicks: 'paid_clicks',
        ctr: 'paid_ctr',
        cost: 'paid_cost',
        cpc: 'paid_cpc',
        quality_score: 'paid_quality_score',
        wqs: 'paid_wqs',
        abs_top_imp_percentage: 'paid_abs_top_imp_percentage',
        abs_top_imp_share: 'paid_abs_top_imp_share',
      }
      if (chartFilters.value.selectedKeyword === 'null') {
        chartFilters.value.selectedKeyword = null
      }
      const keywordParam = chartFilters.value.selectedKeyword ? `&keyword=${chartFilters.value.selectedKeyword}` : ''
      const adAccountParam = chartFilters.value.selectedAdAccount ? `&ad_account_id=${chartFilters.value.selectedAdAccount}` : ''
      const campaignParam = chartFilters.value.selectedCampaign ? `&campaign_id=${chartFilters.value.selectedCampaign}` : ''
      const lookbackParam = chartFilters.value.lookback ? `&lookback=${chartFilters.value.lookback}` : ''
      const metricParam = chartFilters.value.metric ? `&metric=${chartFilters.value.metric}` : ''
      const ctrMinParam = chartFilters.value.ctrMin ? `&ctr_min=${chartFilters.value.ctrMin}` : ''
      const ctrMaxParam = chartFilters.value.ctrMax ? `&ctr_max=${chartFilters.value.ctrMax}` : ''
      const qscoreMinParam = chartFilters.value.qscoreMin ? `&qscore_min=${chartFilters.value.qscoreMin}` : ''
      const qscoreMaxParam = chartFilters.value.qscoreMax ? `&qscore_max=${chartFilters.value.qscoreMax}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/search_timeseries?client_id=${route.params.id}${metricParam}${lookbackParam}${ctrMinParam}${ctrMaxParam}${qscoreMinParam}${qscoreMaxParam}${keywordParam}${adAccountParam}${campaignParam}${lookbackParam}  `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          dataLabels.length = 0
          data.data.forEach((x) => {
            dataLabels.push(x.query_date)
            paidData.push(x[metricMap[chartFilters.value.metric]])
          })
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function loadPaidSearchTerms() {
      searchTerms.length = 0
      const numTermsParam = chartFilters.value.numberTerms ? `&num_records=${chartFilters.value.numberTerms}` : ''
      const adAccountParam = chartFilters.value.selectedAdAccount ? `&ad_account_id=${chartFilters.value.selectedAdAccount}` : ''
      const campaignParam = chartFilters.value.selectedCampaign ? `&campaign_id=${chartFilters.value.selectedCampaign}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/top_search_terms?client_id=${route.params.id}&metric=impressions&lookback=${chartFilters.value.lookback}${adAccountParam}${campaignParam}${numTermsParam}  `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          data.data.forEach((x) => {
            searchTerms.push(x.paid_searchterm)
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function filterTimeline(e) {
      paidData.length = 0
      chartLoaded.value = 0
      paidData.length = 0
      dataLabels.length = 0

      if (e === 'adaccount') {
        // When a user selects and AdAccount fetch campaigns from Vuex store.
        // If the campaigns for the AdAccount does not exist in the Vuex store then fetch from the API.
        // Set the selected Campaign to null
        if (!store.getters.adAccountCampaigns(chartFilters.value.selectedAdAccount).length) {
          // Only fetch Campaigns if the AdAccount has been selected
          if (chartFilters.value.selectedAdAccount) {
            // eslint-disable-next-line
            store.dispatch('getClientAdAccountCampaigns', { clientId, adAccountId:chartFilters.value.selectedAdAccount, accessToken: oktaAuth.getAccessToken() })
          }

          chartFilters.value.selectedCampaign = null
        }
      }

      if (chartFilters.value.selectedAdAccount) {
        adAccounts.value.forEach((acc) => {
          if (acc.adAccountId === chartFilters.value.selectedAdAccount) {
            chartFilters.value.selectedAdAccountName = acc.adAccountName
          }
        })
      } else {
        chartFilters.value.selectedAdAccountName = null
      }
      if (chartFilters.value.selectedCampaign) {
        campaigns.value.forEach((cmpgn) => {
          if (cmpgn.campaignId === chartFilters.value.selectedCampaign) {
            chartFilters.value.selectedCampaignName = cmpgn.campaignName
          }
        })
      } else {
        chartFilters.value.selectedCampaignName = null
      }
      loadPaidSearchTerms()
      loadPaidData()
    }
    function clearFilters() {
      paidData.length = 0
      chartLoaded.value = 0
      chartFilters.value.metric = 'impressions'
      chartFilters.value.lookback = 30
      searchTerms.length = 0
      chartFilters.value.selectedKeyword = null
      chartFilters.value.ctrMin = 0
      chartFilters.value.ctrMax = 1
      chartFilters.value.qscoreMin = 0
      chartFilters.value.qscoreMax = 10
      chartFilters.value.selectedAdAccountName = null
      chartFilters.value.selectedCampaignName = null

      loadPaidData()
      loadPaidSearchTerms()
    }

    onMounted(() => {
      // When the page loads, fetch the Client AdAccounts from Vuex.
      // If Client AdAccount not present in Vuex then fetch them from the API
      if (!store.getters.clientAdAccounts(clientId).length) {
        store.dispatch('getClientAdAccounts', { clientId, accessToken: oktaAuth.getAccessToken() })
      }
      loadPaidData()
      loadPaidSearchTerms()
    })
    return {
      clientId,
      LineGraph,
      chartData,
      paidData,
      chartLoaded,
      loadPaidData,
      chartFilters,
      clearFilters,
      filterTimeline,
      adAccounts,
      campaigns,
      searchTerms,
      lookbackOptions,
      showTruncatedTextWithTitle,
      metricOptions,
    }
  },
}
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}
</style>
