<template>
    <div
      class="sidenav-container"
      :class="{
        'sidenav-container-expand': expandedPanel,
        'sidenav-container-compress': !expandedPanel,
      }"
    >
      <div class="mx-auto px-1 sidenav">
        <div class="d-flex client-title my-3">
          <h1 class="my-auto mx-2" v-if="clientName && expandedPanel">
            {{ clientName }}
          </h1>
          <button class="btn btn-icon expand-btn" @click="sideNavExpandClick">
            <img v-if="expandedPanel" src="@/assets/icons/chevron/left.svg" alt="left arrow in circle" />
            <img v-if="!expandedPanel" src="@/assets/icons/chevron/right.svg" alt="right arrow in circle" />
          </button>
        </div>
        <ul>
          <li :class="{ active: route.name === 'clientDashboard' }">
            <router-link
              :to="{
                name: 'clientDashboard',
                params: routeLinkParams
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Exec Dashboard'"
            >
              <div class="d-flex nav-link">
                <img
                  class="dashboard-icon"
                  src="@/assets/icons/dashboard.svg"
                  alt="table icon"
                />
                <span v-if="expandedPanel">
                  Exec Dashboard
                </span>
              </div>
            </router-link>
          </li>
          <hr class="my-2">
          <li :class="{ active: route.name === 'brandTermsToPause'}">
            <router-link
              :to="{
                name: 'brandTermsToPause',
                params: routeLinkParams
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Paid Brand Terms to Pause'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Paid Brand Terms to Pause
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'genericTermsToActivate'}">
            <router-link
              :to="{
                name: 'genericTermsToActivate',
                params: routeLinkParams
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Paid Generic Terms to Push'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Paid Generic Terms to Push
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'searchLandingPageTestOpportunity'}">
            <router-link
              :to="{
                name: 'searchLandingPageTestOpportunity',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Paid Landing Page Test Opportunity'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Paid Landing Page Test Opportunity
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'organicRankingOnPaidImpressions'}">
            <router-link
              :to="{
                name: 'organicRankingOnPaidImpressions',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Ranking Opportunities Paid Imps'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Ranking Opportunities Paid Imps
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'organicRankingOnOrganicImpressions'}">
            <router-link
              :to="{
                name: 'organicRankingOnOrganicImpressions',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Ranking Opportunities Organic Imps'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Ranking Opportunities Organic Imps
                </span>
              </div>
            </router-link>
          </li>
          <li class="divide-solid" :class="{ active: route.name === 'connectedSearch'}">
            <router-link
              :to="{
                name: 'connectedSearch',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Connected Search'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/table.svg"
                  alt="table icon"
                  class="table-icon"
                />
                <span v-if="expandedPanel">
                  Connected Search
                </span>
              </div>
            </router-link>
          </li>

          <hr class="my-2">

          <li :class="{ active: route.name === 'timeSeries'}">
            <router-link
              :to="{
                name: 'timeSeries',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Connected Time Series'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/bar-chart.svg"
                  alt="bar chart icon"
                  class="bar-chart-icon"
                />
                <span v-if="expandedPanel">
                  Connected <span class="font-semibold">Time Series</span>
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'timeSeriesSearch'}">
            <router-link
              :to="{
                name: 'timeSeriesSearch',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Search Time Series'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/bar-chart.svg"
                  alt="bar chart icon"
                  class="bar-chart-icon"
                />
                <span v-if="expandedPanel">
                  Search <span class="font-semibold">Time Series</span>
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'timeSeriesOrganic'}">
            <router-link
              :to="{
                name: 'timeSeriesOrganic',
                params: {id: clientId, name:clientName}
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Organic Time Series'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/bar-chart.svg"
                  alt="bar chart icon"
                  class="bar-chart-icon"
                />
                <span v-if="expandedPanel">
                  Organic <span class="font-semibold">Time Series</span>
                </span>
              </div>
            </router-link>
          </li>
          <li :class="{ active: route.name === 'organicRankingTimeseries'}">
            <router-link
              :to="{
                name: 'organicRankingTimeseries',
                params: routeLinkParams
              }"
              v-tooltip:right = "expandedPanel ? '' : 'Organic Ranking Time Series'"
            >
              <div class="d-flex nav-link">
                <img
                  src="@/assets/icons/bar-chart.svg"
                  alt="bar chart icon"
                  class="bar-chart-icon"
                />
                <span v-if="expandedPanel">
                  Organic Ranking <span class="font-semibold">Time Series</span>
                </span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
</template>
<script setup>
import {
  ref, getCurrentInstance, onMounted, computed,
} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'

const route = useRoute()
const store = useStore()
const client = ref(null)
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth

const clientId = route.params.id
const clientName = ref(route.params.name)

const routeLinkParams = ref({ id: clientId, name: clientName })

const expandedPanel = computed(() => store.state.isSideNavExpand)

function fetchClient() {
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/${clientId}`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL)
    .then((response) => {
      client.value = response.data
      clientName.value = response.data.name
    })
}

function sideNavExpandClick() {
  store.commit('setSideNavExpandState', !store.state.isSideNavExpand)
}

onMounted(() => {
  if (!clientName.value) {
    fetchClient()
  }
})

</script>

<style lang="scss" scoped>
.sidenav-container {
  transition: width 0.3s ease;
}

.sidenav-container-compress {
  width: 70px;
}

.sidenav ul li:first-child {
  margin-top: 1rem;
}

.sidenav ul li{
  color: #3b3e3f;
  transition-duration: 150ms;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
}

.sidenav ul li a{
  padding: 0.5rem;
  display: block;
}

.sidenav ul li a:hover {
  color: #00A1D1;
}

.sidenav ul li.active{
  background-color: #D7ECF6;
  color: #00A1D1;
}

.sidenav ul li:hover {
  background-color: #D7ECF6;
}

.sidenav-container {
  box-shadow: 0 30px 50px 10px rgb(1 33 78 / 19%);

  .dashboard-icon {
    width: 1.6rem;
    margin-right: 8px;
  }

  .table-icon {
    width: 2rem;
    margin-left: -2px;
    margin-right: 5px;
  }

  .bar-chart-icon {
    width: 1.8rem;
    margin-right: 4px;
  }

  .btn-icon {
    width: 15%;

    img {
      width: 0.9rem;
    }
  }

  .nav-link {
    align-items: center;
  }
}

.sidenav-container-expand {
  min-width: 280px;

  .client-title{
    justify-content: space-between;
    align-items: flex-start;
  }

  .btn-icon {
    width: 15%;
  }
}

.sidenav-container-compress {
  .client-title{
    justify-content: center;
  }

  .nav-link {
    justify-content: center;
  }

  .btn-icon {
    width: 100%;

    img {
      margin: auto;
    }
  }
}

.tooltip {
  display: none;
}

</style>
