<template>
  <div class="max-w-sm rounded overflow-hidden shadow-lg">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ platformName[gAuthType] || ''}}</div>
      <p class="text-gray-700 text-base">
        Use the button below to grant search connect access to
        <b>{{ platformName[gAuthType] || '' }}</b> via Google OAuth{{ routeName === 'clientDetails' ? ' or' : '.'}} <slot></slot>
      </p>
    </div>
    <div class="px-6 pt-4 pb-2 d-flex flex-column">
      <div class="d-flex align-items-center mb-3">
        <span class="d-block me-2">Grant Access via:</span>
        <button type="button" class="login-with-google-btn" @click="getGoogleToken">
          Google
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, getCurrentInstance, computed } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import appConfig from '@/config'
import ScGis from '@/components/gOauth/GoogleOAuthScript'

const props = defineProps({
  gAuthType: String,
  entityId: Number,
  clientId: Number,
})
const emits = defineEmits(['googleAuthClick', 'tokenAdded'])

const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const route = useRoute()

const platformName = {
  PPC: 'Google Ads',
  SC: 'Google Search Console',
  GA: 'Google Analytics',
}

const routeName = computed(() => route.name)

onMounted(() => {
  // Initialise the Google Identity Service within the DOM
  ScGis.createScript()
})

function getGoogleScope() {
  let gScope = 'https://www.googleapis.com/auth/webmasters.readonly'
  if (props.gAuthType === 'GA') {
    gScope = 'https://www.googleapis.com/auth/analytics.readonly'
  }
  if (props.gAuthType === 'PPC') {
    gScope = 'https://www.googleapis.com/auth/adwords'
  }
  return gScope
}

function addClientGAuthCode(gAuthCode) {
  let tokenType = '/clients/google_oauth/'
  let tokenDetails = { client_id: props.entityId, google_code: gAuthCode }

  if (props.clientId) {
    tokenDetails.scope = props.gAuthType
  } else if (props.gAuthType === 'PPC') {
    tokenType = '/ppc_creds/token'
    tokenDetails = { id: props.entityId, ppc_token: gAuthCode }
    if (props.clientId) {
      tokenDetails.client_id = props.clientId
    }
  } else if (props.gAuthType === 'GA') {
    tokenType = '/ga_creds/token'
    tokenDetails = { id: props.entityId, ga_token: gAuthCode }
    if (props.clientId) {
      tokenDetails.client_id = props.clientId
    }
  }
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}${tokenType}`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .post(API_URL, tokenDetails)
    .then(() => {
      emits('tokenAdded')
    })
    .catch((error) => {
      // eslint-disable-next-line
          console.log(error)
    })
    .finally()
}

async function getGoogleToken() {
  emits('googleAuthClick', true)

  const gisClient = window.google.accounts.oauth2.initCodeClient({
    client_id: appConfig.gis.client_id,
    scope: getGoogleScope(),
    ux_mode: 'popup',
    callback: (response) => {
      addClientGAuthCode(response.code)
    },
  })
  gisClient.requestCode()
}
</script>
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:500);

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto';

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
  height: 40px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow:
      0 -1px 0 rgba(0, 0, 0, .04),
      0 2px 4px rgba(0, 0, 0, .25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
}
</style>
