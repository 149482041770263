<template>
  <div
    class="app-content"
    :class="{ 'vh-100': windowPathname === '/login' ||  windowPathname === '/' } "
  >
    <nav-bar></nav-bar>

    <div class="page-content">
      <router-view :key="$route.path"></router-view>
    </div>
    <footer class="center mx-auto">
      <router-link :to="{name: 'privacyPolicy'}">
        Privacy Policy
      </router-link>
      <p class="pad">|</p>
      <router-link :to="{name: 'googleAPIServicesDisclosure'}">
        Google API Services
      </router-link>
    </footer>
  </div>

</template>

<script>
import NavBar from '@/components/Navigation/NavBar.vue'

export default {
  name: 'app',
  methods: {
    async logout() {
      await this.$auth.signOut()
    },
  },
  computed: {
    windowPathname() {
      return window.location.pathname
    },
  },
  components: {
    NavBar,
  },
}
</script>
<style>
  /* needed for omni card*/
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;900&display=swap");
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

 /* Custom Global Styling */
  body {
    min-height: 100vh;
  }

  #app {
    background-color: #EFF4FA;
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
  }

  .app-content {
    min-height: inherit;
    display: flex;
    flex-direction: column;
  }

  /* Container */
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0, 0.04);
    opacity: 1;
    background-color: white;
  }

  .container-title {
    height: 12%;
    border-bottom: 1px solid #F1F6FA;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }

  .container-title__content {
    display: flex;
    align-items: center;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    height: 100%
  }

  .container-content {
    height: 88%;
  }

  .btn {
    border-radius: 10px;
    cursor: pointer;
  }

  .btn-primary {
    background: linear-gradient(90deg, #00A1D2, #03BBF3);
    border: 1px #1ebfff !important;
  }

  .center{
    display: flex;
    justify-content: center;
  }

  .pad{
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
