<template>
  <div class="container mx-auto px-4">
    <h1 class="mt-5 mb-5">Clients</h1>
    <router-link :to="{ name: 'clientAdd' }">
      <button
        id="add_new_client"
        class="
          bg-blue-500
          hover:bg-blue-600
          text-white
          py-2
          px-4
          rounded
          mt-1 mb-5
        "
      >
        Add New Client
      </button>
    </router-link>
    <client-table></client-table>
  </div>
</template>

<script>
import ClientTable from '@/components/clients/ClientTable.vue'

export default {
  name: 'ClientView',
  components: { ClientTable },
}
</script>
