<template>
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 w-full pt-5 overflow-scroll">
      <h1>{{ client }} Next Best Actions</h1>
      <div class="blurb">
        <p> Summary of most impactful opportunities from each detailed tab,
          providing recommendations at a glance.
        </p>
      </div>
      <!-- Brand Terms to Pause Card -->
      <div class="card w-10/12 rounded-lg shadow-lg px-6 py-4 mt-5">
        <!-- eslint-disable-next-line -->
        <Disclosure v-slot="{ open }" :defaultOpen="false">
          <DisclosureButton :class="'w-full flex items-center justify-between'">
            <div class="font-bold h4 text-cyan-700">
                Brand terms to pause
            </div>
             <img
                :class="{'rotate-180' : open}"
                src="@/assets/icons/chevron-down.svg"
                alt="chevron down icon"
              />
          </DisclosureButton>
          <DisclosurePanel>
            <div v-if="brandTermsToPauseLoaded">
              <MetricsTable
                :tableData="tableData.brandTermsToPause"
                :headerData="tableData.sharedPaidHeader"
                :rowMap="tableData.sharedPaidRowMap"
                :showSearch="false"
                :searchPlaceholder="'Search in SearchTerm'"
                :initialSortIndex="'1'"
              />
              <div class="px-6 pt-4 pb-2">
                <router-link
                  :to="{
                    name: 'brandTermsToPause',
                    params: {client_id: clientId}}">
                    <span
                      class="inline-block bg-cyan-200 rounded-full
                      px-3 py-1 text-sm font-semibold text-gray-700
                      mr-2 mb-2">
                      View Dashboard
                    </span>
                  </router-link>
              </div>
            </div>
            <div v-else class="rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold mb-2">
                    Loading Data
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <!-- Generic Terms to Activate Card -->
      <div class="card w-10/12 rounded-lg shadow-lg px-6 py-4 mt-5">
        <!-- eslint-disable-next-line -->
        <Disclosure v-slot="{ open }" :defaultOpen="false">
          <DisclosureButton :class="'w-full flex items-center justify-between'">
            <div class="font-bold h4 text-cyan-700">
                Generic Terms to Activate
            </div>
             <img
                :class="{'rotate-180' : open}"
                src="@/assets/icons/chevron-down.svg"
                alt="chevron down icon"
              />
          </DisclosureButton>
          <DisclosurePanel>
            <div v-if="genericTermsToActivateLoaded">
              <MetricsTable
                :tableData="tableData.genericTermsToActivate"
                :headerData="tableData.sharedPaidHeader"
                :rowMap="tableData.sharedPaidRowMap"
                :showSearch="false"
                :searchPlaceholder="'Search in SearchTerm'"
                :initialSortIndex="'1'"
              />
            <div class="px-6 pt-4 pb-2">
              <router-link
                :to="{
                name: 'genericTermsToActivate',
                params: {client_id: clientId}}">
                <span
                  class="inline-block bg-cyan-200 rounded-full
                  px-3 py-1 text-sm font-semibold text-gray-700
                  mr-2 mb-2">View Dashboard
                </span>
              </router-link>
            </div>
            </div>
            <div v-else class="rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold mb-2">
                    Loading Data
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <!-- Paid Landing Page Test Opportunity Card -->
      <div class="card w-10/12 rounded-lg shadow-lg px-6 py-4 mt-5">
        <!-- eslint-disable-next-line -->
        <Disclosure v-slot="{ open }" :defaultOpen="false">
          <DisclosureButton :class="'w-full flex items-center justify-between'">
            <div class="font-bold h4 text-cyan-700">
                Landing Page Test Opportunity
            </div>
             <img
                :class="{'rotate-180' : open}"
                src="@/assets/icons/chevron-down.svg"
                alt="chevron down icon"
              />
          </DisclosureButton>
          <DisclosurePanel>
            <div v-if="landingPageTestLoaded">
              <MetricsTable
                :tableData="tableData.landingPageTest"
                :headerData="tableData.landingPageTestHeader"
                :rowMap="tableData.landingPageTestRowMap"
                :showSearch="false"
                :searchPlaceholder="'Search in SearchTerm'"
                :initialSortIndex="'1'"
              />
            <div class="px-6 pt-4 pb-2">
              <router-link
                :to="{
                  name: 'searchLandingPageTestOpportunity',
                  params: {client_id: clientId}}">
                  <span
                    class="inline-block bg-cyan-200 rounded-full
                    px-3 py-1 text-sm font-semibold text-gray-700
                    mr-2 mb-2">View Dashboard
                  </span>
              </router-link>
                </div>
            </div>
            <div v-else class="rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold mb-2">
                  Loading Data
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <!-- Organic Ranking Opportunities based on PPC impression Card -->
      <div class="card w-10/12 rounded-lg shadow-lg px-6 py-4 mt-5">
        <!-- eslint-disable-next-line -->
        <Disclosure v-slot="{ open }" :defaultOpen="false">
          <DisclosureButton :class="'w-full flex items-center justify-between'">
            <div class="font-bold h4 text-cyan-700 text-justify">
                Organic Ranking Opportunities based on PPC impression
            </div>
             <img
                :class="{'rotate-180' : open}"
                src="@/assets/icons/chevron-down.svg"
                alt="chevron down icon"
              />
          </DisclosureButton>
          <DisclosurePanel>
            <div v-if="rankingOnPPCImpressionsLoaded">
              <MetricsTable
                :tableData="tableData.rankingOnPPCImpressions"
                :headerData="tableData.sharedPaidHeader"
                :rowMap="tableData.sharedPaidRowMap"
                :showSearch="false"
                :searchPlaceholder="'Search in SearchTerm'"
                :initialSortIndex="'1'"
              />
             <div class="px-6 pt-4 pb-2">
                <router-link
                  :to="{
                  name: 'organicRankingOnPaidImpressions',
                  params: {client_id: clientId}}"
                >
                  <span
                    class="inline-block bg-cyan-200 rounded-full
                    px-3 py-1 text-sm font-semibold text-gray-700
                    mr-2 mb-2">View Dashboard
                  </span>
                </router-link>
              </div>
            </div>
            <div v-else class="rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                <div class="font-bold mb-2">
                    Loading Data
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      <!-- Organic Ranking Opportunities based on Organic impression Card -->
       <div class="card w-10/12 rounded-lg shadow-lg px-6 py-4 mt-5">
        <!-- eslint-disable-next-line -->
        <Disclosure v-slot="{ open }" :defaultOpen="false">
          <DisclosureButton :class="'w-full flex items-center justify-between'">
            <div class="font-bold h4 text-cyan-700 text-justify">
                Organic Ranking Opportunities based on Organic impression
            </div>
             <img
                :class="{'rotate-180' : open}"
                src="@/assets/icons/chevron-down.svg"
                alt="chevron down icon"
              />
          </DisclosureButton>
          <DisclosurePanel>
           <div v-if="rankingOnOrganicImpressionsLoaded">
              <MetricsTable
                :tableData="tableData.rankingOnOrganicImpressions"
                :headerData="tableData.rankingOnOrganicImpressionsHeader"
                :rowMap="tableData.rankingOnOrganicImpressionsRowMap"
                :showSearch="false"
                :searchPlaceholder="'Search in SearchTerm'"
                :initialSortIndex="'1'"
              />
            <div class="px-6 pt-4 pb-2">
              <router-link
                :to="{
                name: 'organicRankingOnOrganicImpressions',
                params: {client_id: clientId}}">
                  <span
                    class="inline-block bg-cyan-200 rounded-full
                    px-3 py-1 text-sm font-semibold text-gray-700
                    mr-2 mb-2">View Dashboard
                  </span>
              </router-link>
            </div>
          </div>
          <div v-else class="rounded overflow-hidden shadow-lg">
            <div class="px-6 py-4">
              <div class="font-bold mb-2">
                  Loading Data
              </div>
            </div>
          </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from 'vue'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import { useRoute } from 'vue-router'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import MetricsTable from '@/components/charts//MetricsTable.vue'

export default {
  name: 'ExecDashboard',
  components: {
    SideNav,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MetricsTable,
  },
  setup() {
    const route = useRoute()
    const clientId = route.params.id
    const client = ref(null)
    const brandTermsToPauseLoaded = ref(false)
    const genericTermsToActivateLoaded = ref(false)
    const landingPageTestLoaded = ref(false)
    const rankingOnPPCImpressionsLoaded = ref(false)
    const rankingOnOrganicImpressionsLoaded = ref(false)
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const tableData = {
      brandTermsToPause: [],
      sharedPaidRowMap: [
        'searchterm',
        'organic_impressions',
        'organic_clicks',
        'organic_ctr',
        'organic_avg_rank',
        'paid_clicks',
        'paid_impressions',
        'paid_cpc',
        'paid_ctr',
        'paid_cost',
      ],
      sharedPaidHeader: [
        'Searchterm',
        'Org Imps',
        'Org Clicks',
        'Org CTR',
        'Org Avg Rank',
        'Paid Clicks',
        'Paid Imps',
        'Paid CPC',
        'Paid CTR',
        'Paid Cost',
      ],
      genericTermsToActivate: [],
      landingPageTest: [],
      landingPageTestRowMap: [
        'searchterm',
        'best_seo_page',
        'best_seo_position',
        'paid_clicks',
        'paid_cost',
        'paid_landingpage',
        'paid_quality_score',
        'landingPageMatch',
      ],
      landingPageTestHeader: [
        'Searchterm',
        'Best Org LP',
        'Highest Org Rank',
        'Paid Clicks',
        'Paid Cost',
        'Paid LP',
        'Paid QScore',
        'LP Match',
      ],
      rankingOnPPCImpressions: [],
      rankingOnOrganicImpressions: [],
      rankingOnOrganicImpressionsRowMap: [
        'seo_query',
        'organic_impressions',
        'organic_clicks',
        'organic_ctr',
        'organic_avg_rank',
      ],
      rankingOnOrganicImpressionsHeader: [
        'Org Query',
        'Org Imps',
        'Org Clicks',
        'Org CTR',
        'Org Avg Rank',
      ],
    }
    const open = ref(false)

    function getBrandTermsToPause() {
      tableData.brandTermsToPause.length = 0
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/brand_terms_to_pause`

      const params = {
        client_id: route.params.id,
        lookback: 7,
        seo_rank: 3,
        num_records: 5,
        csv: false,
      }

      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL, { params })
        .then((data) => {
          tableData.brandTermsToPause = data.data
        })
        .finally(() => {
          brandTermsToPauseLoaded.value = true
        })
    }
    function getGenericTermsToActivate() {
      tableData.genericTermsToActivate.length = 0
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/generic_terms_to_activate?client_id=${route.params.id}&lookback=7&seo_rank=6&num_records=5&order_by=paid_clicks `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          tableData.genericTermsToActivate = data.data
        })
        .finally(() => {
          genericTermsToActivateLoaded.value = true
        })
    }
    function getLandingPageTest() {
      tableData.landingPageTest.length = 0
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/landing_page_test_opportunity?client_id=${route.params.id}&lookback=7&num_records=5 `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          tableData.landingPageTest = data.data
        })
        .finally(() => {
          landingPageTestLoaded.value = true
        })
    }
    function getRankingOpportunitiesOnPPCImpressions() {
      tableData.rankingOnPPCImpressions.length = 0
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/ranking_opportunity_on_search_impressions?client_id=${route.params.id}&lookback=7&seo_rank=6&num_records=5 `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          tableData.rankingOnPPCImpressions = data.data
        })
        .finally(() => {
          rankingOnPPCImpressionsLoaded.value = true
        })
    }
    function getRankingOpportunitiesOnOrganicImpressions() {
      tableData.rankingOnOrganicImpressions.length = 0
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/ranking_opportunity_on_organic_impressions?client_id=${route.params.id}&lookback=7&seo_rank=6&num_records=5 `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          tableData.rankingOnOrganicImpressions = data.data
        })
        .finally(() => {
          rankingOnOrganicImpressionsLoaded.value = true
        })
    }

    onMounted(() => {
      // fetchClient(props.id)
      getBrandTermsToPause()
      getGenericTermsToActivate()
      getLandingPageTest()
      getRankingOpportunitiesOnPPCImpressions()
      getRankingOpportunitiesOnOrganicImpressions()
    })

    return {
      client,
      clientId,
      brandTermsToPauseLoaded,
      genericTermsToActivateLoaded,
      landingPageTestLoaded,
      rankingOnPPCImpressionsLoaded,
      rankingOnOrganicImpressionsLoaded,
      tableData,
      open,
      MetricsTable,
    }
  },
}
</script>

<style>
.h-max{
  min-height: 250px !important;
  max-height: 250px;
}

h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}
</style>
