// eslint-disable-next-line import/prefer-default-export
export const downloadCSVWithResponseBlob = (fileHeaderName, data) => {
  const today = new Date().toLocaleDateString('en-GB').split('/').join('-')
  const fileName = `${fileHeaderName}-${today}.csv`
  const downloadUrl = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')

  link.href = downloadUrl
  link.setAttribute('download', fileName) // any other extension
  document.body.appendChild(link)
  link.click()
  link.remove()
}
