<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex bg-white">
    <SideNav />
    <div class="px-5 min-h-screen w-full mt-5">
      <h1>Connected Search</h1>
      <Disclosure :defaultOpen="true">
      <div class="line">
        <DisclosureButton class="button">
          <img src="@/assets/icons/filter.svg" alt="filter"/> Filter
        </DisclosureButton>
      </div>
      <div class="blurb">
        <p>
          A full view of Paid & Organic metrics side-by-side at the search term level,
          allowing for detailed analysis into key performance KPIs and the ability to filter to specific thresholds.
        </p>
      </div>
      <br> <!-- Account & Campaign Filters -->

      <button
        @click="loadPaidData(true)"
        class="bg-cyan-900 hover:bg-cyan-700 text-white py-1 px-2 mb-3 rounded"
      >
        {{ !isExportingData ? 'Export Table Data' : 'Downloading...'}}
      </button>

      <DisclosurePanel>
          <div class="grid grid-cols-5 pb-5 gap-2">
            <!-- Ad Account Selector -->
            <div>
              <v-select
                v-model="tableFilters.selectedAdAccount"
                :options="adAccounts"
                :reduce="account => account.adAccountId"
                label="adAccountName"
                @update:modelValue="filterTable('adaccount')"
                aria-label="Ad Account Dropdown Filter"
                :placeholder="dropdownPlaceholder.adAccounts "
                @open="dropdownPlaceholder.adAccounts = 'Filter Ad Account'"
                @close="dropdownPlaceholder.adAccounts = 'Select Ad Accounts'"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.adAccountName, $event)"
                    @focus="showTruncatedTextWithTitle(option.adAccountName, $event)"
                  >{{ option.adAccountName }}</span>
                </template>
              </v-select>
            </div>
            <!-- Campaign Selector -->
            <div>
              <v-select
                v-model="tableFilters.selectedCampaign"
                :options="campaigns"
                :reduce="campaign => campaign.campaignId"
                label="campaignName"
                @update:modelValue="filterTable()"
                aria-label="Campaign Dropdown Filter"
                :placeholder="dropdownPlaceholder.campaigns"
                @open="dropdownPlaceholder.campaigns = 'Filter Campaigns'"
                @close="dropdownPlaceholder.campaigns = 'Select Campaigns'"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.campaignName, $event)"
                    @focus="showTruncatedTextWithTitle(option.campaignName, $event)"
                  >{{ option.campaignName }}</span>
                </template>
              </v-select>
            </div>
            <!-- Lookback Selector -->
            <div>
              <v-select
                v-model="tableFilters.lookback"
                :options="lookbackOptions"
                :reduce="lookback => lookback.value"
                @update:modelValue="filterTable()"
                aria-label="Lookback Dropdown Filter"
                :searchable="false"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                    @focus="showTruncatedTextWithTitle(option.label, $event)"
                  >{{ option.label }}</span>
                </template>
              </v-select>
            </div>
            <!-- Reset Filters Button -->
            <div>
              <button class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4" @click="clearFilters('full')">Reset</button>
            </div>
          </div>
          <!-- Metrics Filters -->
          <div class="grid grid-cols-6 pb-5 gap-2">
            <!-- CTR Above Min Selector -->
            <div>
            <!-- eslint-disable-next-line -->
            <label for="ctr-min" class="form-label inline-block mb-1 text-gray-700">CTR Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="ctr-min"
                min="0"
                max="1"
                step="0.1"
              placeholder="CTR Above"
              v-model="tableFilters.ctrMin"
            />
            </div>
          <!-- CTR Below Max Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="ctr-max" class="form-label inline-block mb-1 text-gray-700">CTR Max</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="ctrmax"
                min="0"
                max="1"
                step="0.1"
              placeholder="CTR Below"
              v-model="tableFilters.ctrMax"
            />
          </div>
          <!-- QScore Above Min Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="qscore-min" class="form-label inline-block mb-1 text-gray-700">QScore Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="qscore-min"
                min="0"
                max="10"
                step="1"
              placeholder="QScore Above"
              v-model="tableFilters.qscoreMin"
            />
          </div>
          <!-- QScore Below Max Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="qscore-max" class="form-label inline-block mb-1 text-gray-700">QScore Max</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="qscore-max"
                min="0"
                max="10"
                step="1"
              placeholder="QScore Below"
              v-model="tableFilters.qscoreMax"
            />
          </div>
          <!-- Cost Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="cost-min" class="form-label inline-block mb-1 text-gray-700">Cost Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="cost-min"
              placeholder="Cost Above"
              min="0"
              max=""
              step="1"
              v-model="tableFilters.costMin"
            />
          </div>
          <!-- Cost Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="cost-below" class="form-label inline-block mb-1 text-gray-700">Cost Max</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="cost-below"
              min="0"
              :max="tableFilters.costMax"
              step="1"
              placeholder="Cost Below"
              v-model="tableFilters.costMax"
            />
          </div>
          <!-- Top Imp Share Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="top-imp-share-min" class="form-label inline-block mb-1 text-gray-700">Top Imp Share Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="top-imp-share-min"
              min="0"
              max="1"
              step="0.1"
              placeholder="Top Imp Share Min"
              v-model="tableFilters.topImpShareMin"
            />
          </div>
          <!-- Top Imp Share Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="top-imp-share-max" class="form-label inline-block mb-1 text-gray-700">Top Imp Share Max</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="top-imp-share-max"
              placeholder="Top Imp Share Max"
              min="0"
              max="1"
              step="0.1"
              v-model="tableFilters.topImpShareMax"
            />
          </div>
          <!-- wQS Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="wqs-min" class="form-label inline-block mb-1 text-gray-700">wQS Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="top-imp-share-min"
              min="0"
              max="10"
              step="1"
              placeholder="wQS Min"
              v-model="tableFilters.wqsMin"
            />
          </div>
          <!-- wQS Below Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="wQS-max" class="form-label inline-block mb-1 text-gray-700">wQS Max</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="wQS-max"
              placeholder="wQS Max"
              v-model="tableFilters.wqsMax"
            />
          </div>
          <!-- Imps Above Selector -->
          <div class="mb-3">
            <!-- eslint-disable-next-line -->
            <label for="paid-imps-min" class="form-label inline-block mb-1 text-gray-700">Paid Imps Min</label
            >
            <input
              type="number"
              class="
                form-control
                block
                w-full
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
              "
              id="paid-imps-min"
                min="0"
                :max="tableFilters.impsMax"
                step="1"
              placeholder="Paid Imps Min"
              v-model="tableFilters.paidImpsMin"
            />
          </div>
            <!-- Reset Filters Button -->
            <div>
              <button class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4" @click="filterTable()">Filter</button>
            </div>

          </div>
          </DisclosurePanel>
      <div v-if="chartLoaded">
        <h4 class="mb-10">{{tableData.length}} Rows of data</h4>
        <MetricsTable
          :tableData="tableSearchData.length >= 1 || tableSearchText !== '' ? tableSearchData : tableData"
          :headerData=headerData
          :rowMap=rowMap
          @search-input-change="tableSearchInputEv"
          :showSearch="true"
          :searchPlaceholder="'Search in SearchTerm'"
          :tableType="'searchLandingPageOpportunity'"
          :initialSortIndex="'7'"
          :isSortAscending="false"
        />
      </div>
      <div v-else class="px-5 min-h-screen w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
      </Disclosure>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import {
  ref,
  getCurrentInstance,
  onMounted,
  computed,
} from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import MetricsTable from '@/components/charts/MetricsTable.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import vSelect from 'vue-select'
import useShowTruncatedText from '@/composable/useShowTruncatedText'
import { useStore } from 'vuex'
import MATCHTYPE from '@/utils/constants'
import { downloadCSVWithResponseBlob } from '@/utils/helperFunctions'

export default {
  name: 'ConnectedSearch',
  components: {
    MetricsTable, SideNav, Disclosure, DisclosureButton, DisclosurePanel, vSelect,
  },
  setup() {
    const store = useStore()
    const chartLoaded = ref(null)
    const tableFilters = ref({
      lookback: 3,
      selectedAdAccount: null,
      selectedCampaign: null,
      metric: null,
      ctrMin: 0,
      ctrMax: 1,
      qscoreMin: 0,
      qscoreMax: 10,
      paidImpsMin: 0,
      topImpShareMin: 0,
      topImpShareMax: 1,
      wqsMin: 0,
      wqsMax: 10,
      maxCost: null,
      impsMax: 0,
      costMin: 0,
      costMax: 0,
    })
    const selectedAdAccountId = ref(null)
    const { showTruncatedTextWithTitle } = useShowTruncatedText()
    const searchTerms = ref([])
    const route = useRoute()
    const clientId = route.params.id
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const headerData = [
      'SearchTerm',
      'Paid Match Type',
      'Org Imps',
      'Org Clicks',
      'Org CTR',
      'Org Max Rank',
      'Avg Rank',
      'Paid Imps',
      'Paid Clicks',
      'Paid CPC',
      'Paid CTR',
      'Paid Cost',
      'Paid QScore',
      'Paid wQS',
      'Paid Abs Top Imp Share',
      'Paid Abs Top Imp %',
    ]
    const tableData = ref([])
    const tableSearchText = ref('')
    const tableSearchData = ref([])
    const rowMap = [
      'searchterm',
      'paid_matchtype',
      'organic_impressions',
      'organic_clicks',
      'organic_ctr',
      'organic_rank_max',
      'organic_avg_rank',
      'paid_impressions',
      'paid_clicks',
      'paid_cpc',
      'paid_ctr',
      'paid_cost',
      'paid_qscore',
      'paid_wqs',
      'paid_absolute_top_imp_share',
      'paid_abs_top_imp_pcntg',
    ]
    const dropdownPlaceholder = ref({
      adAccounts: 'Select Ad Account',
      campaigns: 'Select Campaign',
      searchTerms: 'Select Search Term',
    })
    const lookbackOptions = [
      { label: 'Latest Data', value: 3 },
      { label: '7 Day view', value: 7 },
      { label: '30 Day view', value: 30 },
    ]

    const isExportingData = ref(false)

    const adAccounts = computed(() => store.getters.clientAdAccounts(clientId))
    const campaigns = computed(() => store.getters.adAccountCampaigns(tableFilters.value.selectedAdAccount))
    /**
     * handle table search event for:
     * - search term
     *
     * @param {String} searchText - search text
     */
    function tableSearchInputEv(searchText) {
      tableSearchText.value = searchText

      if (searchText !== '') {
        tableSearchData.value = tableData.value
          .filter((data) => data.searchterm.includes(searchText))
      } else {
        tableSearchData.value = []
      }
    }

    function loadPaidData(downloadAsCSV = false) {
      if (downloadAsCSV) {
        isExportingData.value = true
      } else {
        tableData.value.length = 0
        chartLoaded.value = false
      }

      const ctrMinParam = tableFilters.value.ctrMin ? `&ctr_min=${tableFilters.value.ctrMin}` : ''
      const ctrMaxParam = tableFilters.value.ctrMax ? `&ctr_max=${tableFilters.value.ctrMax}` : ''
      const qscoreMinParam = tableFilters.value.qscoreMin ? `&qscore_min=${tableFilters.value.qscoreMin}` : ''
      const qscoreMaxParam = tableFilters.value.qscoreMax ? `&qscore_max=${tableFilters.value.qscoreMax}` : ''
      const costMinParam = tableFilters.value.costMin ? `&cost_min=${Math.ceil(tableFilters.value.costMin)}` : ''
      const costMaxParam = tableFilters.value.costMax ? `&cost_max=${Math.ceil(tableFilters.value.costMax)}` : ''
      const topImpShareMinParam = tableFilters.value.topImpShareMin ? `&top_imp_share_min=${tableFilters.value.topImpShareMin}` : ''
      const topImpShareMaxParam = tableFilters.value.topImpShareMax ? `&top_imp_share_max=${tableFilters.value.topImpShareMax}` : ''
      const wqsMinParam = tableFilters.value.wqsMin ? `&wqs_min=${tableFilters.value.wqsMin}` : ''
      const wqsMaxParam = tableFilters.value.wqsMax ? `&wqs_max=${tableFilters.value.wqsMax}` : ''
      const paidImpsMinParam = tableFilters.value.paidImpsMin ? `&paid_imps_min=${tableFilters.value.paidImpsMin}` : ''
      const campaignParam = tableFilters.value.selectedCampaign ? `&campaign_id=${tableFilters.value.selectedCampaign}` : ''
      const adAccountParam = tableFilters.value.selectedAdAccount ? `&ad_account_id=${tableFilters.value.selectedAdAccount}` : ''
      const csvParam = `&csv=${downloadAsCSV}`

      // eslint-disable-next-line
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/search_connect?client_id=${route.params.id}&lookback=${tableFilters.value.lookback}${ctrMinParam}${ctrMaxParam}${qscoreMinParam}${qscoreMaxParam}${costMinParam}${costMaxParam}${topImpShareMinParam}${topImpShareMaxParam}${wqsMinParam}${wqsMaxParam}${paidImpsMinParam}${campaignParam}${adAccountParam}${csvParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((response) => {
          if (downloadAsCSV) {
            downloadCSVWithResponseBlob('connected-search', response.data)

            isExportingData.value = false
          } else {
            tableData.value = response.data.map((datum) => ({
              ...datum,
              paid_matchtype: MATCHTYPE[Number(datum.paid_matchtype)],
            }))
            return response.data
          }

          return []
        })
        .then((data) => {
          if (!downloadAsCSV) {
            data.forEach((x) => {
              if (x.paid_cost > tableFilters.value.costMax) {
                tableFilters.value.costMax = x.paid_cost
              }
              if (x.paid_impressions > tableFilters.value.impsMax) {
                tableFilters.value.impsMax = x.paid_impressions
              }
            })
          }
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value = true
        })
    }

    function clearFilters() {
      tableData.value.length = 0
      tableFilters.value.lookback = 3
      tableFilters.value.selectedAdAccount = null
      tableFilters.value.selectedCampaign = null
      tableFilters.value.ctrMin = 0
      tableFilters.value.ctrMax = 1
      tableFilters.value.qscoreMin = 0
      tableFilters.value.qscoreMax = 10
      tableFilters.value.paidImpsMin = 0
      tableFilters.value.costMin = 0
      tableFilters.value.costMax = null
      tableFilters.value.topImpShareMin = 0
      tableFilters.value.topImpShareMax = 1
      tableFilters.value.wqsMin = 0
      tableFilters.value.wqsMax = 10
      loadPaidData()
    }

    function filterTable(e) {
      if (e === 'adaccount') {
        // When a user selects and AdAccount fetch campaigns from Vuex store.
        // If the campaigns for the AdAccount does not exist in the Vuex store then fetch from the API.
        // Set the selected Campaign to null
        if (!store.getters.adAccountCampaigns(tableFilters.value.selectedAdAccount).length) {
          // Only fetch Campaigns if the AdAccount has been selected
          if (tableFilters.value.selectedAdAccount) {
            // eslint-disable-next-line
            store.dispatch('getClientAdAccountCampaigns', { clientId, adAccountId:tableFilters.value.selectedAdAccount, accessToken: oktaAuth.getAccessToken() })
          }

          tableFilters.value.selectedCampaign = null
        }
      }
      loadPaidData()
    }
    onMounted(() => {
      loadPaidData()
      // When the page loads, fetch the Client AdAccounts from Vuex.
      // If Client AdAccount not present in Vuex then fetch them from the API
      if (!store.getters.clientAdAccounts(clientId).length) {
        store.dispatch('getClientAdAccounts', { clientId, accessToken: oktaAuth.getAccessToken() })
      }
    })
    return {
      clientId,
      headerData,
      chartLoaded,
      loadPaidData,
      filterTable,
      clearFilters,
      MetricsTable,
      tableData,
      rowMap,
      adAccounts,
      tableFilters,
      campaigns,
      searchTerms,
      tableSearchText,
      tableSearchData,
      tableSearchInputEv,
      showTruncatedTextWithTitle,
      dropdownPlaceholder,
      lookbackOptions,
      selectedAdAccountId,
      isExportingData,
    }
  },
}
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}

</style>
