<template>
  <div class="container mx-auto px-4">
    <p class="text-2xl dark:text-white p-t20 my-3">
      PPC Auth: {{ ppcAuth ? ppcAuth.name : "" }}
    </p>
    <google-o-auth-button
      :entityId="Number(ppcAuthIdFromRoute)"
      :gAuthType="'PPC'"
      @token-added="tokenAddedEvent"
      class="mt-5 mb-5"
    >
    </google-o-auth-button>

    <br />

    <ppc-auth-url-button
      class="mt-5"
      :ppcId="Number(ppcAuthIdFromRoute)"
      :gAuthType="'PPC'"
      @auth-url-fetched="ppcAuthUrlFetched"
    ></ppc-auth-url-button>
    <div v-if="fetchedUrl">
      <div class="text-right">
        <button class="mb-0 mt-5" @click="copySignedUrl">
          <copy-icon/>
        </button>
      </div>
      <div
        class="
          mt-0
          p-4
          mb-4
          text-sm text-green-700
          bg-green-100
          rounded-lg
          dark:bg-green-200 dark:text-green-800
          break-all
        "
        role="alert"
        ref="signedUrl"
      >
        {{ fetchedUrl }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import GoogleOAuthButton from '@/components/gOauth/GoogleOAuthButton.vue'
import CopyIcon from '@/components/icons/CopyIcon.vue'
import PpcAuthUrlButton from '@/components/ppcAuth/PpcAuthUrlButton.vue'

export default {
  components: { GoogleOAuthButton, CopyIcon, PpcAuthUrlButton },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const ppcAuth = ref(null)
    const loading = ref()
    const ppcAuthIdFromRoute = ref(route.params.id)
    const fetchedUrl = ref(null)
    const authUrl = ref(null)

    function copySignedUrl() {
      navigator.clipboard.writeText(fetchedUrl.value)
    }

    function ppcAuthUrlFetched(authUrlValue) {
      fetchedUrl.value = authUrlValue
    }

    function tokenAddedEvent() {
      router.push({ name: 'ppcAuth' })
    }

    function fetchPpcAuth(ppcAuthId) {
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/ppc_creds/${ppcAuthId}`
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((response) => {
          ppcAuth.value = response.data
          fetchedUrl.value = null
          authUrl.value = ''
        })
        .finally(() => {
          loading.value = false
        })
    }
    onMounted(() => {
      fetchPpcAuth(route.params.id)
      ppcAuthIdFromRoute.value = route.params.id
    })
    return {
      ppcAuth,
      ppcAuthIdFromRoute,
      fetchedUrl,
      authUrl,
      ppcAuthUrlFetched,
      copySignedUrl,
      tokenAddedEvent,
    }
  },
}

</script>
