<template>
  <div class="fill-screen flex">
    <div class="md:w-1/3 mx-auto">
    <form class="w-full w-full m-10">
      <div class="md:flex md:items-center mb-6">
        <div class="w-100">
          <label
            for="client-name"
            class="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
          >
            Client Name:
            <input
              class="
                bg-gray-100
                appearance-none
                border-2 border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-purple-500
              "
              id="client-name"
              type="text"
              v-model="clientName"
              ari-label="Client name"
            />
          </label>
          <label
            for="client-table-prefix"
            class="
              block
              text-gray-500
              font-bold
              md:text-left
              mb-1
              md:mb-0
              pr-4
              mt-5
            "
          >
            Client Table Prefix:
            <input
              class="
                bg-gray-100
                appearance-none
                border-2 border-gray-200
                rounded
                w-full
                py-2
                px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-purple-500
              "
              id="client-table-prefix"
              type="text"
              v-model="clientTablePrefix"
              ari-label="Client table prefix"
            />
          </label>
        </div>
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-2/3">
          <button
            class="
              shadow
              bg-cyan-500
              hover:bg-purple-400
              focus:shadow-outline focus:outline-none
              text-white
              font-bold
              py-2
              px-4
              rounded
            "
            type="button"
            @click="addClient"
          >
            Add Client
          </button>
        </div>
      </div>
    </form>
    </div>
    <div
      v-if="clientAddError || clientValidationError"
      class="
        bg-red-100
        border border-red-400
        text-red-700
        px-4
        py-3
        rounded
        relative
        w-full
        mt-5
      "
      role="alert"
    >
      <span class="block sm:inline ml-2">{{ errorMessage }}.</span>
      <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
        <svg
          class="fill-current h-6 w-6 text-red-500"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <!-- eslint-disable -->
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
          <!-- eslint-enable -->
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      clientName: '',
      clientTablePrefix: '',
      clientAdded: false,
      clientAddError: false,
      clientValidationError: false,
      errorMessage: '',
    }
  },
  methods: {
    addClient() {
      this.validateClient()
      if (!this.validateClient()) {
        return false
      }
      this.clientAdded = false
      this.clientAddError = false
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/`
      axios.defaults.headers.common.Authorization = `Bearer ${this.$auth.getAccessToken()}`
      axios
        .post(API_URL, { name: this.clientName, client_table_prefix: this.clientTablePrefix })
        .then(() => {
          this.clientAdded = true
          this.$router.push({ name: 'clients' })
        })
        .catch(() => {
          this.clientAddError = true
          this.errorMessage = 'error saving client'
        })
      return true
    },
    validateClient() {
      let validated = true
      if (!this.clientName) {
        this.clientValidationError = true
        this.errorMessage = 'Client name cannot be empty'
        validated = false
      }
      const tablePrefixPattern = /^[a-zA-Z0-9_]+$/
      if (!tablePrefixPattern.test(this.clientTablePrefix)) {
        this.errorMessage = 'Client table prefix can only contain alphabets, numbers or an underscore'
        this.clientValidationError = true
        validated = false
      }

      if (!this.clientTablePrefix) {
        this.errorMessage = 'table prefix cannot be empty'
        this.clientValidationError = true
        validated = false
      }
      return validated
    },
  },
}
</script>
