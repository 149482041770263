<!-- eslint-disable -->
<template>
  <div class="container mt-10 px-4 md:w-2/3 mx-auto">
    <h1>Version History</h1>
    <div class="m-10 mx-auto">
      <div class="update">
          <h4>
          0.9 | Enhancement
        </h4>
        <p>
          Implemented Database Materialised Views to speed up API calls and implemented
          Airflow script to refresh views after daily data ingestion
        </p>
      </div>
      <div class="update">
          <h4>
          0.89 | Bug
        </h4>
        <p>
          Fixed issue with trailing forward slash on Organic & Paid URLs was
          causing false negatives on the Landing PAge Test dashbaord
        </p>
      </div>
      <div class="update">
          <h4>
          0.88 | Enhancement
        </h4>
        <p>
          	Added blurbs to allthe dashboards
        </p>
      </div>
      <div class="update">
          <h4>
          0.87 | Enhancement
        </h4>
        <p>
          Updated searchTimeSeries to include metrics filter
        </p>
      </div>
      <div class="update">
          <h4>
          0.86 | Bug
        </h4>
        <p>
          Fixed issue with trailing forward slash on Organic & Paid URLs was
          causing false negatives on the Landing PAge Test dashbaord
        </p>
      </div>
      <div class="update">
          <h4>
          0.85 | Enhancement
        </h4>
        <p>
          Renamed all instances of PPC to Paid and SEO to Organic
        </p>
      </div>
      <div class="update">
          <h4>
          0.84 | Bug
        </h4>
        <p>
          Fixed duplicate colon on pages where count was being displayed
        </p>
      </div>
      <div class="update">
          <h4>
          0.83 | Bug
        </h4>
        <p>
          Fixed incorrect title on Paid Landing Page Test Opportunity
        </p>
      </div>
      <div class="update">
          <h4>
          0.82 | Dashboard
        </h4>
        <p>
          Added Organic Rank time series to show top 10 ranking terms based on SEO Impressions
          along with their average max rank for the day over a time period.
          Filters allow a user to drill into specific terms based on the submitted params.
        </p>
      </div>
      <div class="update">
        <h4>
          0.81.8 | Enhancement
        </h4>
        <p>
          Renamed instanced of SEO to Organic and PPC to Paid across all views.
        </p>
      </div>
      <div class="update">
        <h4>
          0.81.6 | Enhancement
        </h4>
        <p>
          RenamedGeneric Terms to Activate -> Generic Terms to Push as some terms were already
          showing in the PPC data.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.7 | Enhancement
        </h4>
        <p>
          Categorised the side nav, moved Timeseries to bottom and added divider to segment the charts.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.5 | Enhancement
        </h4>
        <p>
          Turned the Exec Dashboard card views into tables - allows for sorting also.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.4 | Back End
        </h4>
        <p>
          Added initialSortIndex and isSortAscending props to the MetricsTable component allowing for datas orting when initialising
          data in the table.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.3 | Bug Fix
        </h4>
        <p>
          Reduced the amount of data initially loaded into the Brands Terms to Pause dashboard to 250 rows including a botton
          to load all data if necessary - reduces view loading time.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.2 | Enhancement
        </h4>
        <p>
          Updated Timeseries views to include Filter Tags to indicate which settings have been applied to the vurrent data view.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81.1 | Bug Fix
        </h4>
        <p>
          Updated Searchterm dropdown to show top 1000 results as the volume of terms was causing the
          dropdown to not render or be very laggy.
        </p>
      </div>
      <div class="update">
          <h4>
          0.81 | Bug Fix
        </h4>
        <p>
          Added lookback selector to Search Time Series Dashboard
        </p>
      </div>
      <div class="update">
          <h4>
          0.8 | Bug Fix
        </h4>
        <p>
          Removed the params from the PPC URLs in Paid Landing Page Test Opportunity
          Dashboard as it was causing False Negatives when trying to match with best SEO URL.
        </p>
      </div>
      <div class="update">
        <h4>
          0.1
        </h4>
        <p>
          Platfrom deployment with NSPCC Test data - replicating Data Studio charts
        </p>
      </div>
    </div>
  </div>
</template>

<style>

h4{
  color: rgb(12 74 110);
  font-weight: 700 !important;
}
p{
  color: rgb(6, 45, 67);
}
.update{
  margin-bottom: 1em;
}

</style>
