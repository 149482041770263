<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full mt-5">
        <h1>Organic TimeSeries</h1>
        <div class="blurb">
          <p>
            Filter to specific search terms or view an account as a whole to identify Organic Ranking trends over time,
            whether this be for macro strategic purposes or to analyse the impact of any tests being conducted.
          </p>
        </div>
      <Disclosure defaultOpen=true>
      <div class="line">
      <DisclosureButton class="button">
          <img src="@/assets/icons/filter.svg" alt="filter"/> Filter
        </DisclosureButton>
      </div>
      <div v-if="chartLoaded == 2">
      <div class="w-full pt-10 px-5">
      <DisclosurePanel>
            <div class="grid grid-cols-4 gap-2">
        <!-- Metrics Selector -->
        <div>
          <v-select
            v-model="chartFilters.metric"
            :options="metricOptions"
            :reduce="metric => metric.value"
            @update:modelValue="filterTimeline()"
            aria-label="Metric Dropdown Filter"
            :searchable="false"
            :clearable="false"
          >
            <template v-slot:option="option">
              <span
                @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                @focus="showTruncatedTextWithTitle(option.label, $event)"
              >{{ option.label }}</span>
            </template>
          </v-select>
        </div>
        <!-- Lookback Selector -->
        <div>
          <v-select
            v-model="chartFilters.lookback"
            :options="lookbackOptions"
            :reduce="lookback => lookback.value"
            @update:modelValue="filterTimeline()"
            aria-label="Look Back Dropdown"
            :searchable="false"
            :clearable="false"
          >
            <template v-slot:option="option">
              <span
                @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                @focus="showTruncatedTextWithTitle(option.label, $event)"
              >{{ option.label }}</span>
            </template>
          </v-select>
        </div>
          <!-- SEO Query Selector -->
          <div>
            <v-select
              v-model="chartFilters.selectedKeyword"
              :options="chartFilters.queries"
              @update:modelValue="filterTimeline()"
              aria-label="SEO Query Dropdown"
              :searchable="false"
              :clearable="false"
            >
              <template v-slot:option="option">
                <span
                  @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                  @focus="showTruncatedTextWithTitle(option.label, $event)"
                >{{ option.label }}</span>
              </template>
            </v-select>
          </div>
        <!-- Reset Filters Button -->
        <div>
          <button class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4 rounded" @click="clearFilters('full')">Reset</button>
        </div>
      </div>
      </DisclosurePanel>
      <div class=" mt-10">
            <div class="my-5">
              <span v-if="chartFilters.metric" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Metric | {{ chartFilters.metric }}</span>
              <span v-if="chartFilters.lookback" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Lookback | {{ chartFilters.lookback + ' days' }}</span>
              <span v-if="chartFilters.selectedKeyword" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">SEO Query | {{ chartFilters.selectedKeyword }}</span>
            </div>
        <h4 v-if="chartFilters.selectedKeyword" >Filter | {{ chartFilters.selectedKeyword }}</h4>
        <LineGraph :chartData=chartData :yAxisLabel=chartFilters.metric />
      </div>
      </div>
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data...</h1>
      </div>
      </Disclosure>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { ref, getCurrentInstance, onMounted } from 'vue'
import LineGraph from '@/components/charts/LineGraph.vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import vSelect from 'vue-select'
import useShowTruncatedText from '@/composable/useShowTruncatedText'

export default {
  name: 'OrganicTimeSeriesDashboard',
  components: {
    LineGraph, SideNav, vSelect, Disclosure, DisclosureButton, DisclosurePanel,
  },
  setup() {
    const organicData = []
    const chartLoaded = ref(0)
    const chartFilters = {
      metric: 'impressions',
      lookback: 30,
      queries: [],
      selectedKeyword: null,
      numberTerms: 1000,
    }
    const route = useRoute()
    const clientId = route.params.id
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const dataLabels = []
    const chartData = {
      labels: dataLabels,
      datasets: [{
        label: 'Organic TimeSeries',
        data: organicData,
        borderColor: 'rgb(14, 165, 233)',
      },
      ],
    }
    const metricOptions = [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'CTR', value: 'ctr' },
      { label: 'max_rank', value: 'Max Rank' },
      { label: 'avg_rank', value: 'Average Rank' },
    ]
    const lookbackOptions = [
      { label: '7 Day View', value: '7' },
      { label: '30 Day view', value: '30' },
      { label: '60 Day view', value: '60' },
      { label: '90 Day view', value: '90' },
    ]
    const { showTruncatedTextWithTitle } = useShowTruncatedText()

    function loadOrganicData(metric = 'impressions', lookback = '30', keyword = null) {
      const metricMap = {
        impressions: 'seo_impressions',
        clicks: 'seo_clicks',
        ctr: 'seo_ctr',
        max_rank: 'seo_max_rank',
        avg_rank: 'seo_avg_rank',
      }
      const keywordParam = keyword ? `&keyword=${keyword}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/organic_timeseries?client_id=${route.params.id}&metric=${metric}&lookback=${lookback}${keywordParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          dataLabels.length = 0
          data.data.forEach((x) => {
            dataLabels.push(x.query_date)
            organicData.push(x[metricMap[metric]])
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function loadOrganicQueries(lookback = '30') {
      const numTermsParam = chartFilters.numberTerms ? `&num_records=${chartFilters.numberTerms}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/top_queries?client_id=${route.params.id}&metric=impressions&lookback=${lookback}${numTermsParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          data.data.forEach((x) => {
            chartFilters.queries.push(x.seo_query)
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function filterTimeline() {
      organicData.length = 0
      chartLoaded.value = 1
      organicData.length = 0
      dataLabels.length = 0
      loadOrganicData(chartFilters.metric, chartFilters.lookback, chartFilters.selectedKeyword)
    }
    function clearFilters() {
      organicData.length = 0
      chartFilters.metric = 'impressions'
      chartLoaded.value = 0
      chartFilters.lookback = 30
      chartFilters.queries.length = 0
      chartFilters.selectedKeyword = null
      loadOrganicData(chartFilters.metric, chartFilters.lookback, chartFilters.selectedKeyword)
      loadOrganicQueries()
    }

    onMounted(() => {
      loadOrganicData()
      loadOrganicQueries()
    })
    return {
      clientId,
      LineGraph,
      chartData,
      organicData,
      chartLoaded,
      loadOrganicData,
      chartFilters,
      clearFilters,
      filterTimeline,
      metricOptions,
      showTruncatedTextWithTitle,
      lookbackOptions,
    }
  },
  methods: {
  },
}
</script>

<style>

.mt-15{
  margin-top: 5em !important;
}
canvas{
  height: 600px !important;
}
h1{
  margin-top: 2.5rem;
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 700 !important;
  color: rgb(12 74 110);
}

</style>
