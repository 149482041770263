<!-- eslint-disable -->
<template>
  <div class="container mt-10 px-4 md:w-2/3 mx-auto">
    <h1>Privacy Policy</h1>
    <div class="m-10 mx-auto">
      <div class="row justify-content-center">
        <p>
          <span>Last modified May 23<sup>th</sup>, 2023 with an effective
              date of Jan 1<sup>st</sup>, 2021
          </span>
        </p>
        <span>
            <hr class="my-5 center">
        </span>
        <p>
        <span>This privacy policy
            (&quot;<b>Policy</b>&quot;)
            informs you of practices when handling your Information through the Services
            (both as defined below). In this Policy, &quot;<b>OMG</b>&quot;, &quot;<b>we</b>&quot;
            or &quot;<b>us</b>&quot; refers to <b>Omnicom Media Group UK </b>Ltd., a
            company registered 90-100 Southwark St, London SE1 0SW
        </span>
        </p>

        <p><span>We are the data controller under the applicable privacy laws.</span> </p>
        <br>
        <p class="mb-5 item">
          <span>For the purpose of this Policy, &quot;<b>Information</b>&quot;
              means any information relating to an identified or identifiable individual.
              This includes Information you provide or generated when you use: (a) our apps,
              including searchConnect (&quot;<b>Website</b>&quot; and together with the &quot;<b>Apps</b>&quot;
              and the &quot;<b>Services</b>&quot;). When you use the Services, you accept and
              understand we collect, process, use and store your Information as described in
              this Policy.&nbsp;<b>If you do not agree with this Policy, you must not use any
                  of the Services. If you change your mind in the future, you must stop using the
                  Services and you may exercise your rights in relation to your Information as
                  set out in this Policy.</b>
          </span>
        </p>

        <p><b><span>1. Information we collect</span></b></p>

        <p> <span>We will collect and use the following Information about you:</span></p>

        <ul style='margin-top:0cm' type=disc>
            <li>
                <b><span>Information you provide to us</span></b><span></span>
            </li>
            <ul style='margin-top:0cm' type=circle>
                <li>
                    <b>
                        <span>
                            Registration
                            <span>information
                                <span style='font-weight:normal'>&nbsp;:</span>
                            </span>
                        </span>
                    </b>
                    <span> for example,
                        when you log in and authenticate account the Website,
                        you will be asked to provide your email & password. <span>
                          </span>These will be used to authenticate via Okta SSO and
                          your email address will be used in the application to show your logged in status
                    </span>
                </li>
                <li>
                    <b><span>Information collected via voluntary
                            activities:</span></b><span>&nbsp;for example, when
                        you communicate with us via email, or participate in surveys, we ask you
                        to provide your email address and any Information you choose to provide
                        to allow us to assist you. The Information we collect from you varies
                        depending on the survey, but we typically ask you questions about your
                        interests and habits, your opinion about different products and services
                        and what <span>you‘</span>d like to be offered in the future.
                        We also collect your ratings, opinions, preferences, questions, and
                        responses associated with that survey.</span>
                </li>
            </ul>
            <li>
                <b><span>Information we automatically collect or
                        is generated about you when use the Services</span></b><span>

                </span>
            </li>
            <ul type=circle>
                <li>
                    <b><span>Identifiers</span></b><span>, such as your IP address, device ID,
                        and device information (such as model, brand and operating system).</span>
                </li>
                <li>
                    <b><span>Cookies:</span></b><span>&nbsp;we use cookies and other similar
                        technologies (&quot;<b>Cookies</b>&quot;) to enhance your experience when
                        using the Services. For more information about our Cookies policy, see
                        below&nbsp;<b>How We Use Cookies and Similar Technologies section.</b>
                    </span>
                </li>
            </ul>
        </ul>

        <p><b><span>Children</span></b><span> </span></p>

        <p>
            <span>Our Services are not targeted at children, and we
                do not knowingly collect Information from children under the age of 18. If you
                learn that a child has provided us with Information in violation of this
                Policy, please contact us as indicated below.</span>
        </p>
        <br>
        <p><b><span>2. How we use your personal information</span></b></p>

        <p>
            <span>We use your Information to:&nbsp;<b>Provide you
                    with the Services.</b>&nbsp;We will use your Information to perform our
                contractual obligation towards you to allow you to create an account and use
                the Services. The Information we process when doing so includes your
                registration information, information you provide to us when using the
                Services, identifiers, information you generate when using the Services, and
                information regarding your use of the Services. </span>
        </p>

        <p>
            <b><span>Improve and monitor the Services.</span></b><span>&nbsp;It is in our legitimate interests to
                improve
                our Services for our customers. When doing so, we may collect information we
                automatically collect or is generated about you when you use the Services, as
                well as non-personal information about your device such as device manufacturer,
                model and operating system, and the amount of free space on your device.</span>
        </p>

        <ul type=disc>
            <li>
                <b><span>Provide you with support and to respond
                        to your requests or complaints.</span></b><span>&nbsp;If you reach out to us for support, we
                    will use your
                    Information to respond to and resolve your queries and complaints and
                    facilitate support (<span>e.g.</span> retrieval of a forgotten
                    password). When doing so, we perform our contractual obligation towards
                    you. The Information we process when doing so includes your registration
                    information, your identifiers, and any other information about you
                    collected via our customer support channels.</span>
            </li>
            <li>
                <b><span>Conduct analytics.</span></b><span>&nbsp;It is in our legitimate interests
                    to analyse the use of, and any other interaction or interest in our
                    Services. When doing so we will process information we automatically
                    collect or is generated about you when you use the Services to create
                    anonymised and aggregated data regarding your App usage.</span>
            </li>
            <li>
                <b><span>Prevent fraud, defend OMG against legal
                        claims or disputes, enforce our terms and to comply with our legal
                        obligations.</span></b><span>&nbsp;It is in our
                    legitimate interest to protect our interests by (1) monitoring the use of
                    the Services to detect fraud or any other user behaviour which prejudices
                    the integrity of our Services, (2) taking steps to remedy aforementioned
                    fraud and behaviour, (3) defending ourselves against legal claims or disputes,
                    and (4) enforcing our terms and policies. When doing so, we will process
                    the Information relevant in such a case, including information you provide
                    us, information we automatically collect about you, and information which
                    is provided to us by third parties.</span>
            </li>
            <li><b><span>Conduct surveys.</span></b><span>&nbsp;From time to time, we may ask you
                    to participate in surveys we conduct which are in our legitimate interest
                    because they help us understand our userbase and improve the Services. If
                    you participate, we process your registration information and any other
                    information collected through the survey questions.</span></li>
        </ul>
        <br>
        <p><b><span>3. How we use cookies and similar technologies</span></b></p>

        <p>
            <span>Cookies are small files of letters and numbers that
                we store on your browser or the hard drive of your computer. They contain information
                that is transferred to your <span>computer‘</span>s hard drive.</span>
        </p>

        <p>
            <span>Our Services uses Cookies to collect information
                about your browsing activities and to distinguish you from other users of our
                Services. This aids your experience when you use our Services and also allows
                us to improve the functionality of our Services.</span>
        </p>

        <p><span>We use some or all of the following cookies:</span></p>

        <ul type=disc>
            <li>
                <b><span>Strictly necessary cookies:</span></b><span>&nbsp;Some cookies are strictly
                    necessary to make our Services available to you; for example, to perform
                    your login functionality and for user authentication and security. We
                    cannot provide you with the Services without this type of cookies.</span>
            </li>
            <li>
                <b><span>Functional <span>cookies<span
                                style='font-weight:normal'>&nbsp;:</span></span></span></b><span> These are used to
                    recognise you when
                    you return to our Website. This enables us to personalise our content for
                    you, greet you by name and remember your preferences (for example, your
                    choice of language or region).</span>
            </li>
            <li>
                <b><span>Analytical or performance
                        cookies</span></b><span>. We also use cookies for analytics
                    purposes in order to operate, maintain, and improve our Services. We use
                    third party analytics providers, including Google Analytics, to help us
                    understand how users engage with the Services. Google Analytics uses
                    first-party cookies to track user interactions which helps show how users
                    use our Services. This information is used to compile reports and to help
                    us improve our Services. The reports disclose Website trends without
                    identifying individual visitors. You can opt out of Google Analytics
                    without affecting how you visit our Website by going to&nbsp;</span><span><a
                        href="https://tools.google.com/dlpage/gaoptout"><span><u>https://tools.google.com/dlpage/gaoptout</u></span></a></span><span>&nbsp;or
                    via <span>Google‘</span>s
                    Ads settings.</span>
            </li>
        </ul>

        <p>
            <span>You can block cookies by setting your internet
                browser to block some or all cookies. However, if you use your browser settings
                to block all cookies (including essential cookies) you may not be able to use
                our Services.<br>
                Except for essential cookies, all cookies will expire after maximum 2 years.</span>
        </p>
        <br>
        <p><b><span>4. Who we share your personal information with</span></b></p>

        <p><span>We share your Information with selected third parties, including:</span></p>

        <ul type=disc>
            <li>
                <b><span>Vendors and service <span>providers</span>
                        we rely on for the provision of the Services</span></b><span>, for example:</span>
            </li>
            <li>
                <b><span>Cloud service providers</span></b><span>&nbsp;who we rely on for data storage,
                    including Amazon Web Services who are based in the <span>U.S.;</span>

                </span>
            </li>
            <li>
                <b><span>Analytics providers</span></b><span>. We work with a number of analytics
                    providers who help us understand our userbase. This includes Google LLC,
                    which is based in the U.S. You can learn about Google‘s practices by going
                    to&nbsp;</span><span><a
                        href="https://www.google.com/policies/privacy/partners/"><span><u>https://www.google.com/policies/privacy/partners/</u></span></a></span><span>,
                    and opt-out of them by downloading
                    the Google Analytics opt-out browser add-on, available at&nbsp;</span><span><a
                        href="https://tools.google.com/dlpage/gaoptout"><span><u>https://tools.google.com/dlpage/gaoptout</u></span></a></span><span>.</span>
            </li>
            <li>
                <b><span>Communications platform
                        providers</span></b><span>, who help us manage and send email
                    alerts to you in relation to the Services. </span>
            </li>
            <li>
                <b><span>Law enforcement agencies, public
                        authorities or other judicial bodies and organisations.</span></b><span>&nbsp;We disclose
                    Information if we are
                    legally required to do so, or if we have a good faith belief that such use
                    is reasonably necessary to comply with a legal obligation, process or
                    request; enforce our terms of service and other agreements, policies, and
                    standards, including investigation of any potential violation thereof;
                    detect, prevent or otherwise address security, fraud or technical issues;
                    or protect the rights, property or safety of us, our users, a third party
                    or the public as required or permitted by law (including exchanging
                    information with other companies and organisations for the purposes of
                    fraud protection).</span>
            </li>
        </ul>

        <p>
            <span>Although we have in place security measures to
                maintain the privacy and integrity of your Information, the transmission of
                Information via the internet is not completely secure. We may also take extra
                steps to protect your Information and minimise the Information we process. For
                example, when we store your Information, we use AES 256 encryption, and when we
                send or receive your Information, it is encrypted with TLS 1.1 or above.
                Additionally, we are not responsible for how <span>third party</span>
                integration services may collect, use or share the Information you send from
                the Apps. Please review the privacy policy of such <span>third
                    party</span> integration partners before connecting those services to the Apps.
            </span>
        </p>
        <br>
        <p><b><span>5. Where we store your information </span></b> </p>

        <p>
            <span>Your Information will be processed by our employees
                and service providers in the EU. We take steps to ensure all transfers are
                protected by adequate safeguards, including the standard contractual clauses
                approved by the European Commission.</span>
        </p>
        <br>
        <p><b><span>6. How long we store your information </span></b> </p>

        <p>
            <span>Your Information is kept for as long as necessary
                to achieve the purposes set out above. Generally, it is stored for as long as
                you are registered and using our Services, and then for up to 6 years from the
                date you stop using the Services, or promptly following a valid erasure request
                (see below _&nbsp;<b>Your Rights: Erasure</b>&nbsp;_&nbsp;section). Some
                information we collect will be stored for longer where we have an overriding
                legitimate interest to retain such information (for example, information on
                suspicious behaviour of certain users of our Services and transaction records).</span>
        </p>

        <p>
            <span>When deleting Information, we will take measures to
                make the Information irrecoverable or irreproducible, and electronic files
                which contain Information will be deleted permanently.</span>
        </p>
        <br>
        <p><b><span>7. Your rights</span></b></p>

        <p>
            <span>If you are based in the EEA, you have certain
                rights in relation to your Information. You will find more information below on
                when which rights can apply. To exercise your rights, please contact us
                at&nbsp;</span><span><a
                    href="mailto:accuenanalyticsuk@accuenmedia.com"><span><u>accuenanalytics@accuenmedia.com</u></span></a></span><span>

            </span>
        </p>

        <ul type=disc>
            <li>
                <b><span>Access.</span></b><span>&nbsp;You have the right to access
                    Information, and to receive an explanation of how we use it and who we
                    share it with. We provide full access to your Information when requested
                    via &nbsp;</span><span><a
                        href="accuenanalytics@accuenmedia.com"><span><u>accuenanalytics@accuenmedia.com</u></span></a></span><span>

                </span>
            </li>
        </ul>

        <p>
            <span>The right to access is not absolute. For example,
                we cannot reveal trade secrets, or give you Information about other
                individuals.
            </span>
        </p>

        <ul type=disc>
            <li>
                <b><span>Erasure.</span></b><span>&nbsp;You have the right to delete your
                    account and erase your Information and upon deleting your account, all
                    your Information will be removed from our production systems. Usually,
                    only an encrypted copy of your Information will remain on our backup
                    archives for 90 days, although we reserve the right to retain some of your
                    Information where there are valid grounds for us to do so under data
                    protection laws. For example, for the defence of legal claims, respect
                    freedom of expression, or where we have an overriding legitimate interest
                    to do so.</span>
            </li>
        </ul>

        <p class="p-2" style='margin-left:36.0pt;background:white'>
            <span>Note
                that
                where
                the Information is held by a <span>third party</span> data
                controller, we will use reasonable steps to inform them of your request, but we
                recommend you contact them directly in accordance with their own privacy
                policies to ensure your personal data is erased.</span>
        </p>

        <ul type=disc>
            <li>
                <b><span>Objection.</span></b><span>&nbsp;You may have the right to object
                    to our processing of you Information. This is the case where we process
                    such Information on the basis of our legitimate interests (see above under
                    <b>How we use your personal information</b> section). You may exercise
                    this right as follows:</span>
            </li>
            <li>
                <span>To
                    stop our cookies being placed for
                    either advertising or analytics purposes: please change your device or
                    browser settings.</span>
            </li>
            <li>
                <span>To
                    object to all other processing based
                    on our legitimate interests, please contact us at </span><span><a
                        href="mailto:accuenanalytics@accuenmedia.com"><span><u>accuenanalytics@accuenmedia.com</u></span></a></span><span>
                    Please note that we may have an
                    overriding legitimate interest to keep processing your Information, but we
                    will let you know where this is the case.</span>
            </li>
        </ul>

        <p><b><span>Other rights</span></b><span> </span></p>

        <p><span>You also have the following rights:</span></p>

        <ul style='margin-top:0cm' type=disc>
            <li>
                <b><span>Portability.</span></b><span>&nbsp;You have the right to receive a
                    copy of Information we process on the basis of consent or contract in a structured,
                    commonly used and machine-readable format, or to request that such
                    Information is transferred to a third party.</span>
            </li>
            <li>
                <b><span>Correction</span></b><span>. You have the right to correct any
                    Information held about you that is inaccurate.</span>
            </li>
            <li>
                <b><span>Restriction</span></b><span>. You have a right in certain
                    circumstances to stop us processing Information other than for storage
                    purposes.</span>
            </li>
        </ul>
        <br>
        <p><b><span>8. Contact &amp; complaints </span></b> </p>

        <p>
            <span>We welcome questions, comments and requests
                regarding this Policy. For additional details and frequently asked questions
                about our policies, please contact
            </span>
            <span style='color:black;mso-color-alt:windowtext'>
                <a href="mailto:accuenanalytics@accuenmedia.com">
                    <span><u>accuenanalytics@accuenmedia.com</u></span>
                </a>
            </span>
            <span>.</span>
        </p>

        <p>
            <b>
                <span>If you wish to make a complaint about how we
                    process your Information, please email us at </span>
            </b>
            <span>
                <a href="mailto:accuenanalytics@accuenmedia.com">
                    <span><u>accuenanalytics@accuenmedia.com</u></span></a></span><span>
                <b>and we will
                    endeavour
                    to
                    deal with your
                    complaint as soon as possible. This is without prejudice to your right to
                    launch a claim with a data protection authority.</b>&nbsp;
            </span>
        </p>
        <br>
        <p><b><span>9. Changes</span></b></p>

        <p>
            <span>If we make any material changes to this Policy, we
                will post the updated Policy here and notify our users through the Services
                and/or newsletters. Please check this page frequently to see any updates or
                changes to this Policy.
            </span>
        </p><br><br><br>

        <h1><b><span>Google Ads API</span></b></h1>
        <p>
          We use the Google Ads API integration and adhere to the <span><a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
          <span><u>Google API Services User Data Policy</u></span></a></span>, including the Limited Use requirements.</p><br><br>
        <p>
          You authorise searchConnect to extract Google Ads Data for up to and including the past 90 days.
          This information is processed and provided to you within searchConnect for the use of reporting
          and performance insights.
        </p><br><br><br>
        <p>
          The data we analyse and share is only visible to users within OmnicomMediaGroup and then only users of whom you
          provide access to within your searchConnect client team. The data is not shared with any third party and not
          accessible to anyone outside of OmnicomMediaGroup.
        </p><br><br><br>
        <p>
          In order for searchConnect to process your Google Ads account data, you must give permission by linking your
          Google Ads API. Should you unlink your Google Ads API at any time, searchConnect will immediately stop processing
          your data.
        </p><br><br><br>

        <p><b><span>Google Ads information we collect, process and store</span></b></p>
        <p>When you link your Google Ads API you give searchConnect the permission to collect, process and store data from your
          Google Ads account at an account, campaign, ad Group and keyword level including:
        </p><br><br><br>
          <ul>
            <li>Clicks</li>
            <li>Impressions</li>
            <li>Impression Share</li>
            <li>keywords</li>
            <li>searchterms</li>
          </ul><br><br><br><br><br><br>
        <p>
          We shall continue to process this information until access is revoked or the contract between OmnicomMediaGroup,
          or one of OmnicomMediaGroup's Agencies is terminated.
        </p><br><br><br>
        <p>
          On first linking your Google Ads API access to searchConnect we proceed to extract the previous 90 days data and
          store this in a secure database.
          Following this, we process your Google Ads information daily for the past 24 hours. Each day the information is
          processed and saved to a database.
          We hold 90 days data in our database with the 91st day's data deleted daily.
        </p><br><br><br><br>

        <p><b><span>Google Ads information we display</span></b></p>
        <p>
          The Google Ads information collected on a daily basis is displayed to OmnicomMediaGroup's employees only inline
          with your agreement in the corresponding reporting and dashboard views.
        </p><br><br><br>

        <p><b><span>Information we share</span></b></p>
        <p>
          We do not disclose or share Google Ads information to any third party.
        </p><br><br>

        <h1>Google Search Console API</h1>

        <p>
          We use the Google Search Console API integration and adhere adhere to the
          <span><a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
          <span><u>Google API Services User Data Policy</u></span></a></span>
          , including the Limited Use requirements.
        </p><br><br>
        <p>
          You authorise searchConnect to extract Google Search Console Data for up to and including the past 90 days.
          This information is processed and provided to you within searchConnect for the use of reporting and performance
          insights.
        </p><br><br><br>
        <p>
          The data we analyse and share is only visible to users within OmnicomMediaGroup and then only users of whom you
          provide access to within your searchConnect client team. The data is not shared with any third party and not
          accessible to anyone outside of OmnicomMediaGroup.
        </p><br><br><br>
        <p>
          In order for searchConnect to process your Google Search Console data, you must give permission by linking your
          Google Search Console API. Should you unlink your Google Search Console API at any time, searchConnect will
          immediately stop processing your data.
        </p><br><br><br>
        <p><b><span>Google Search Console information we collect, process and store</span></b></p>
        <p>
          When you link your Google Search Console API you give searchConnect the permission to collect, process and store
          data from your Google Search Console including:
        </p><br><br><br>
        <ul>
          <li>Clicks</li>
          <li>Impressions</li>
          <li>SEO Query</li>
          <li>Page URL</li>
        </ul><br><br><br><br><br>
        <p>
          We shall continue to process this information until access is revoked or the contract between OmnicomMediaGroup,
          or one of OmnicomMediaGroup's Agencies is terminated.
        </p><br><br><br>
        <p>
          On first linking your Google Search Console API access to searchConnect we proceed to extract the previous
          90 days data and store this in a secure database.
          Following this, we process your Google Ads information daily for the past 24 hours.
          Each day the information is processed and saved to a database.
          We hold 90 days data in our database with the 91st day's data deleted daily.
        </p><br><br><br><br>

        <p><b><span>Google Search Console information we display</span></b></p>
        <p>
          The Google Search Console information collected on a daily basis is displayed to OmnicomMediaGroup's employees only
          inline with your agreement in the corresponding reporting and dashboard views.
        </p><br><br><br>

        <p><b><span>Information we share</span></b></p>
        <p>
          We do not disclose or share Google Search Console information to any third party.
        </p><br><br>

        <h1>Google Analytics API</h1>
        <p>
          We use the Google Search Analytics API integration and adhere adhere to the
          <span><a
          href="https://www.google.com/analytics/terms/">
          <span><u> Analytics Terms</u></span></a></span>
           and inline with
          <span><a
          href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
          <span><u>Google API Services User Data Policy</u></span></a></span>
           including the Limited
          Use requirements.
          You authorise searchConnect to extract Google Analytics for up to and including the past 90 days.
          This information is processed and provided to you within searchConnect for the use of reporting and performance
          insights.
        </p><br><br><br><br>
        <p>
          The data we analyse and share is only visible to users within OmnicomMediaGroup and then only users of whom you
          provide access to within your searchConnect client team. The data is not shared with any third party and not
          accessible to anyone outside of OmnicomMediaGroup.
        </p><br><br><br>
        <p>
          In order for searchConnect to process your Google Analytics data, you must give permission by linking your
          Google Analytics API. Should you unlink your Google Analytics API at any time, searchConnect will
          immediately stop processing your data.
        </p><br><br><br>

         <p><b><span>Google Analytics information we collect, store & process</span></b></p>
        <p>
          When you link your Google Analytics API you give searchConnect the permission to collect, process and store data
          from your Google Analytics including:
        </p><br><br>

        <ul>
          <li>Campaign</li>
          <li>Full Page Url</li>
          <li>Google Ads Account Name</li>
          <li>Conversion</li>
          <li>Google Ads Query</li>
          <li>Event Name</li>
        </ul><br><br><br><br><br><br><br>

        <p>
          We shall continue to process this information until access is revoked or the contract between OmnicomMediaGroup,
          or one of OmnicomMediaGroup's Agencies is terminated.
        </p><br><br><br>

        <p>
          On first linking your Google Analytics API access to searchConnect we proceed to extract the previous
          90 days data and store this in a secure database.
          Following this, we process your Google Analytics information daily for the past 24 hours.
          Each day the information is processed and saved to a database.
          We hold 90 days data in our database with the 91st day's data deleted daily.
        </p><br><br><br><br>

         <p><b><span>Google Analytics information we display</span></b></p>
        <p>
          The Google Analytics information collected on a daily basis is displayed to OmnicomMediaGroup's employees only
          inline with your agreement in the corresponding reporting and dashboard views.
        </p><br><br><br>

         <p><b><span>Information we share</span></b></p>
        <p>
          We do not disclose or share Google Analytics information to any third party.
        </p><br><br><br>

      </div>
    </div>
  </div>
</template>

<style>

h4{
  color: rgb(12 74 110);
  font-weight: 700 !important;
}
p{
  color: rgb(6, 45, 67);
}
.update{
  margin-bottom: 1em;
}
.item{
  margin-bottom: 5px;
}

</style>
