import { createRouter, createWebHistory } from 'vue-router'
import { LoginCallback, navigationGuard } from '@okta/okta-vue'
// import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ClientView from '@/views/clients/ClientView.vue'
import ClientDetailView from '@/views/clients/ClientDetailView.vue'
import ClientAddView from '@/views/clients/ClientAddView.vue'
import AboutView from '@/views/AboutView.vue'
import ClientUrlSignature from '@/views/clients/ClientUrlSignature.vue'
import ExecDashboard from '@/views/dashboards/ExecDashboard.vue'
import TimeSeriesDashboard from '@/views/dashboards/TimeSeriesDashboard.vue'
import SearchTimeSeriesDashboard from '@/views/dashboards/SearchTimeSeriesDashboard.vue'
import OrganicTimeSeriesDashboard from '@/views/dashboards/OrganicTimeSeriesDashboard.vue'
import SearchBrandTermsToPause from '@/views/dashboards/SearchBrandTermsToPause.vue'
import GenericTermsToActivate from '@/views/dashboards/SearchGenericTermsToActivate.vue'
import OrganicRankingOnPaidImpressions from '@/views/dashboards/OrganicRankingOnPaidImpressions.vue'
import OrganicRankingOnOrganicImpressions from '@/views/dashboards/OrganicRankingOnOrganicImpressions.vue'
import SearchLandingPageTestOpportunity from '@/views/dashboards/SearchLandingPageTestOpportunity.vue'
import PpcAuthView from '@/views/ppc/PpcAuthView.vue'
import PpcAuthDetail from '@/views/ppc/PpcAuthDetail.vue'
import PpcSignedUrl from '@/views/ppc/PpcSignedUrlView.vue'
import PpcAuthAddView from '@/views/ppc/PpcAuthAddView.vue'
import GaAuthView from '@/views/ga/GaAuthView.vue'
import GaAuthDetailView from '@/views/ga/GaAuthDetailView.vue'
import GaSignedUrlView from '@/views/ga/GaSignedUrlView.vue'
import GaAuthAddView from '@/views/ga/GaAuthAddView.vue'
import ConnectedSearch from '@/views/dashboards/ConnectedSearch.vue'
import VersionHistory from '@/views/VersionHistory.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import GoogleAPIServicesDisclosure from '@/views/GoogleAPIServicesDisclosure.vue'
import OrganicRankingTimeSeries from '@/views/dashboards/OrganicRankingTimeSeries.vue'
import apiInterceptor from '@/router/APIInterceptor'
import AuthErrorView from '@/views/AuthErrorView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: AboutView,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: AuthErrorView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/login/callback',
    component: LoginCallback,
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/ppc-auth',
    name: 'ppcAuth',
    component: PpcAuthView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ppc-auth/:id',
    name: 'ppcAuthDetail',
    component: PpcAuthDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ppc-auth/add',
    name: 'ppcAuthAdd',
    component: PpcAuthAddView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ppc-auth/:id/signed-url/:signedUrl',
    name: 'ppcSignedUrl',
    component: PpcSignedUrl,
  },
  {
    path: '/ga-auth',
    name: 'gaAuth',
    component: GaAuthView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/ga-auth/:id',
    name: 'gaAuthDetail',
    component: GaAuthDetailView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ga-auth/:id/signed-url/:signedUrl',
    name: 'gaSignedUrl',
    component: GaSignedUrlView,
  },
  {
    path: '/ga-auth/add',
    name: 'gaAuthAdd',
    component: GaAuthAddView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/add',
    name: 'clientAdd',
    component: ClientAddView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id',
    name: 'clientDetails',
    component: ClientDetailView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/signed-url/:signedUrl/:scope',
    name: 'clientUrlSignature',
    component: ClientUrlSignature,
  },
  {
    path: '/clients',
    name: 'clients',
    component: ClientView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards',
    name: 'clientDashboard',
    component: ExecDashboard,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/timeseries',
    name: 'timeSeries',
    component: TimeSeriesDashboard,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/search/timeseries',
    name: 'timeSeriesSearch',
    component: SearchTimeSeriesDashboard,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/organic/timeseries',
    name: 'timeSeriesOrganic',
    component: OrganicTimeSeriesDashboard,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/search/brand_terms_to_pause',
    name: 'brandTermsToPause',
    component: SearchBrandTermsToPause,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/search/generic_terms_to_activate',
    name: 'genericTermsToActivate',
    component: GenericTermsToActivate,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/organic/organic_ranking_opportunity_paid_imps',
    name: 'organicRankingOnPaidImpressions',
    component: OrganicRankingOnPaidImpressions,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/organic/organic_ranking_opportunity_organic_imps',
    name: 'organicRankingOnOrganicImpressions',
    component: OrganicRankingOnOrganicImpressions,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/search/landing_page_test_opportunity',
    name: 'searchLandingPageTestOpportunity',
    component: SearchLandingPageTestOpportunity,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/dashboards/search/connectedsearch',
    name: 'connectedSearch',
    component: ConnectedSearch,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/versioning',
    name: 'versionHistory',
    component: VersionHistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/privacy_policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/google_api_services_disclosure',
    name: 'googleAPIServicesDisclosure',
    component: GoogleAPIServicesDisclosure,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/clients/:id/dashboards/rank_timeseries/',
    name: 'organicRankingTimeseries',
    component: OrganicRankingTimeSeries,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(navigationGuard)
apiInterceptor(router)
export default router
