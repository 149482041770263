export default function useShowTrucatedText() {
  function showTruncatedTextWithTitle(text, event) {
    if (event.target.offsetWidth < event.target.scrollWidth) {
      event.target.setAttribute('title', text)
    }
  }

  return {
    showTruncatedTextWithTitle,
  }
}
