<template>
  <!-- eslint-disable max-len -->
  <!-- eslint-disable no-trailing-spaces -->
  <div class="flex dashboard-content bg-white">
    <SideNav />
    <div class="px-5 overflow-scroll w-full pt-5">
      <h1 class="font-bold text-2xl">Connected TimeSeries
      </h1>
      <div class="blurb">
        <p>
          Timeline of both Paid & Organic impressions to help identify trends over time,
          with the ability to filter to specific search terms to analyse the impact of any tests being conducted.
        </p>
      </div>
      <Disclosure defaultOpen=true>
        <div class="line">
        <DisclosureButton class="button">
            <img src="@/assets/icons/filter.svg" alt="filter"/> Filter
        </DisclosureButton>
        </div>
      <div v-if="chartLoaded > 1">
        <div class="w-full pt-10 px-5">
          <!-- Account & Campaign Filters -->
          <DisclosurePanel>
          <div class="grid grid-cols-4 pb-5 gap-2">
          </div>
          <div class="grid grid-cols-4 pb-5 gap-2">
            <!-- Ad Account Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.selectedAdAccount"
                :options="adAccounts"
                label="adAccountName"
                :reduce="adAccount => adAccount.adAccountId"
                @update:modelValue="filterTimeline('adaccount')"
                :placeholder="dropdownPlaceholder.adAccounts"
                @open="dropdownPlaceholder.adAccounts = 'Filter Ad Account'"
                @close="dropdownPlaceholder.adAccounts = 'Select Ad Accounts'"
                aria-label="Ad Accounts Dropdown Filter"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.adAccountName, $event)"
                    @focus="showTruncatedTextWithTitle(option.adAccountName, $event)"
                  >{{ option.adAccountName }}</span>
                </template>
              </v-select>
            </div>
            <!-- Campaign Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.selectedCampaign"
                :options="campaigns"
                :reduce="campaign => campaign.campaignId"
                label="campaignName"
                @update:modelValue="filterTimeline()"
                :placeholder="dropdownPlaceholder.campaigns"
                @open="dropdownPlaceholder.campaigns = 'Filter Campaigns'"
                @close="dropdownPlaceholder.campaigns = 'Select Campaign'"
                aria-label="Campaigns Dropdown Filter"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.campaignName, $event)"
                    @focus="showTruncatedTextWithTitle(option.campaignName, $event)"
                  >{{ option.campaignName }}</span>
                </template>
              </v-select>
            </div>
            <!-- Paid SearchTerm Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.selectedKeyword"
                :options="searchTerms"
                label="index"
                @update:modelValue="filterTimeline()"
                :placeholder="dropdownPlaceholder.searchTerms"
                @open="dropdownPlaceholder.searchTerms = 'Filter Search Terms'"
                @close="dropdownPlaceholder.searchTerms = 'Select Search Term'"
                aria-label="Search Terms Dropdown Filter"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.index, $event)"
                    @focus="showTruncatedTextWithTitle(option.index, $event)"
                  >{{ option.index }}</span>
                </template>
              </v-select>
            </div>
          </div>
          <!-- Metrics Filters -->
          <div class="grid grid-cols-4 gap-2">
            <!-- Metrics Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.metric"
                :options="impressionOptions"
                :reduce="impression => impression.value"
                @update:modelValue="filterTimeline()"
                aria-label="Metric Dropdown Filter"
                :searchable="false"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                    @focus="showTruncatedTextWithTitle(option.label, $event)"
                  >{{ option.label }}</span>
                </template>
              </v-select>
            </div>
            <!-- Lookback Selector -->
            <div class="w-5/6">
              <v-select
                v-model="chartFilters.lookback"
                :options="lookbackOptions"
                :reduce="lookback => lookback.value"
                @update:modelValue="filterTimeline()"
                aria-label="Lookback Dropdown Filter"
                :searchable="false"
                :clearable="false"
              >
                <template v-slot:option="option">
                  <span
                    @mouseover="showTruncatedTextWithTitle(option.label, $event)"
                    @focus="showTruncatedTextWithTitle(option.label, $event)"
                  >{{ option.label }}</span>
                </template>
              </v-select>
            </div>
            <!-- Reset Filters Button -->
            <div>
              <button class="bg-cyan-500 rounded-lg hover:bg-cyan-600 text-white font-bold py-2 px-4" @click="clearFilters('full')">Reset</button>

            </div>
            <!-- SEO Query Selector -->
            <!-- <div class="mb-3 xl:w-96">
              <select class="form-select appearance-none
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding bg-no-repeat
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" @change="refreshTimeline()" v-model="chartFilters.selectedKeyword">
                <option
                    v-for="query in chartFilters.queries"
                    :value="query"
                    :key="query"
                >
                  {{query}}
                </option>
              </select>
            </div> -->
          </div>
          </DisclosurePanel>
          <div class="mt-10">
            <div class="my-5">
              <span v-if="chartFilters.metric" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Metric | {{ chartFilters.metric }}</span>
              <span v-if="chartFilters.lookback" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Lookback | {{ chartFilters.lookback + ' days' }}</span>
              <span v-if="chartFilters.selectedKeyword" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Searchterm | {{ chartFilters.selectedKeyword }}</span>
              <span v-if="chartFilters.selectedCampaign" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">Campaign | {{ chartFilters.selectedCampaignName }}</span>
              <span v-if="chartFilters.selectedAdAccount" class="bg-cyan-100 text-cyan-800 text-xs font-semibold mr-2 px-3 py-1 mt-1 rounded dark:bg-cyan-200 dark:text-cyan-900">AdAccount | {{ chartFilters.selectedAdAccountName }}</span>
            </div>
            <div v-if="chartLoaded == 2">
              <LineGraph :chartData=chartData :yAxisLabel=chartFilters.metric />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="px-5 w-full mt-10">
        <h1>Loading Data... </h1>
      </div>
      </Disclosure>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import {
  ref, getCurrentInstance, onMounted, computed,
} from 'vue'
import LineGraph from '@/components/charts/LineGraph.vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import SideNav from '@/components/Navigation/SideNav.vue'
import vSelect from 'vue-select'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import useShowTruncatedText from '@/composable/useShowTruncatedText'
import { useStore } from 'vuex'

export default {
  name: 'TimeSeriesDashboard',
  components: {
    LineGraph, SideNav, vSelect, Disclosure, DisclosureButton, DisclosurePanel,
  },
  setup() {
    const store = useStore()
    const paidData = []
    const organicData = []
    const chartLoaded = ref(0)
    const { showTruncatedTextWithTitle } = useShowTruncatedText()
    const chartFilters = ref({
      metric: 'impressions',
      lookback: 30,
      selectedKeyword: null,
      selectedAdAccount: null,
      selectedAdAccountName: null,
      selectedCampaign: null,
      selectedCampaignName: null,
      numberTerms: 1000,
    })
    const route = useRoute()
    const clientId = route.params.id
    const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
    const dataLabels = []
    const searchTerms = []
    const chartData = {
      labels: dataLabels,
      datasets: [{
        label: 'Paid Search TimeSeries',
        data: paidData,
        borderColor: 'rgb(14, 165, 233)',
        yAxisID: 'y',
      },
      {
        label: 'Organic Timeseries',
        data: organicData,
        borderColor: '#1f324f',
        yAxisID: 'y',
      },
      ],
    }

    // Dropdown variables
    const impressionOptions = [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'CTR', value: 'ctr' },
    ]
    const lookbackOptions = [
      { label: '7 Day View', value: '7' },
      { label: '30 Day view', value: '30' },
      { label: '60 Day view', value: '60' },
      { label: '90 Day View', value: '90' },
    ]
    const dropdownPlaceholder = ref({
      adAccounts: 'Select Ad Account',
      campaigns: 'Select Campaign',
      searchTerms: 'Select Search Term',
    })

    const adAccounts = computed(() => store.getters.clientAdAccounts(clientId))
    const campaigns = computed(() => store.getters.adAccountCampaigns(chartFilters.value.selectedAdAccount))

    function loadPaidData() {
      const metricMap = {
        impressions: 'paid_impressions',
        clicks: 'paid_clicks',
        ctr: 'paid_ctr',
      }
      const keywordParam = chartFilters.value.selectedKeyword ? `&keyword=${chartFilters.value.selectedKeyword}` : ''
      const adAccountParam = chartFilters.value.selectedAdAccount ? `&ad_account_id=${chartFilters.value.selectedAdAccount}` : ''
      const campaignParam = chartFilters.value.selectedCampaign ? `&campaign_id=${chartFilters.value.selectedCampaign}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/search_timeseries?client_id=${route.params.id}&metric=${chartFilters.value.metric}&lookback=${chartFilters.value.lookback}${keywordParam}${adAccountParam}${campaignParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          data.data.forEach((x) => {
            dataLabels.push(x.query_date)
            paidData.push(x[metricMap[chartFilters.value.metric]])
          })
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function loadOrganicData() {
      const metricMap = {
        impressions: 'seo_impressions',
        clicks: 'seo_clicks',
        ctr: 'seo_ctr',
      }
      const keywordParam = chartFilters.value.selectedKeyword ? `&keyword=${chartFilters.value.selectedKeyword}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/organic/organic_timeseries?client_id=${route.params.id}&metric=${chartFilters.value.metric}&lookback=${chartFilters.value.lookback}${keywordParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          data.data.forEach((x) => {
            organicData.push(x[metricMap[chartFilters.value.metric]])
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
        .finally(() => {
          chartLoaded.value += 1
        })
    }
    function loadPaidSearchTerms() {
      searchTerms.length = 0
      const numberTermsParam = chartFilters.value.numberTerms ? `&num_records=${chartFilters.value.numberTerms}` : ''
      const adAccountParam = chartFilters.value.selectedAdAccount ? `&ad_account_id=${chartFilters.value.selectedAdAccount}` : ''
      const campaignParam = chartFilters.value.selectedCampaign ? `&campaign_id=${chartFilters.value.selectedCampaign}` : ''
      const API_URL = `${process.env.VUE_APP_API_DOMAIN}/dashboards/search/top_search_terms?client_id=${route.params.id}&metric=impressions&lookback=${chartFilters.value.lookback}${adAccountParam}${campaignParam}${numberTermsParam} `
      axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
      axios
        .get(API_URL)
        .then((data) => {
          data.data.forEach((x) => {
            searchTerms.push(x.paid_searchterm)
          })
        })
        .catch((error) => {
          throw new Error(`API ${error}`)
        })
    }

    function filterTimeline(e) {
      chartLoaded.value = 0
      organicData.length = 0
      paidData.length = 0
      dataLabels.length = 0

      if (e === 'adaccount') {
        // When a user selects and AdAccount fetch campaigns from Vuex store.
        // If the campaigns for the AdAccount does not exist in the Vuex store then fetch from the API.
        // Set the selected Campaign to null
        if (!store.getters.adAccountCampaigns(chartFilters.value.selectedAdAccount).length) {
          // Only fetch Campaigns if the AdAccount has been selected
          if (chartFilters.value.selectedAdAccount) {
            // eslint-disable-next-line
            store.dispatch('getClientAdAccountCampaigns', { clientId, adAccountId:chartFilters.value.selectedAdAccount, accessToken: oktaAuth.getAccessToken() })
          }
          chartFilters.value.selectedCampaign = null
        }
      }

      if (chartFilters.value.selectedAdAccount) {
        adAccounts.value.forEach((acc) => {
          if (acc.adAccountId === chartFilters.value.selectedAdAccount) {
            chartFilters.value.selectedAdAccountName = acc.adAccountName
          }
        })
      } else {
        chartFilters.value.selectedAdAccountName = null
      }
      if (chartFilters.value.selectedCampaign) {
        campaigns.value.forEach((cmpgn) => {
          if (cmpgn.campaignId === chartFilters.value.selectedCampaign) {
            chartFilters.value.selectedCampaignName = cmpgn.campaignName
          }
        })
      } else {
        chartFilters.value.selectedCampaignName = null
      }
      loadPaidSearchTerms()
      loadPaidData()
      loadOrganicData()
    }
    function clearFilters() {
      chartFilters.value.metric = 'impressions'
      chartFilters.value.lookback = 30
      searchTerms.length = 0
      chartFilters.value.selectedKeyword = null
      chartFilters.value.selectedAdAccount = null
      chartFilters.value.selectedCampaign = null
      chartFilters.value.selectedAdAccountName = null
      chartFilters.value.selectedCampaignName = null
      filterTimeline()
    }

    onMounted(() => {
      // When the page loads, fetch the Client AdAccounts from Vuex.
      // If Client AdAccount not present in Vuex then fetch them from the API
      if (!store.getters.clientAdAccounts(clientId).length) {
        store.dispatch('getClientAdAccounts', { clientId, accessToken: oktaAuth.getAccessToken() })
      }
      loadPaidSearchTerms()
      loadPaidData()
      loadOrganicData()
    })
    return {
      clientId,
      LineGraph,
      chartData,
      paidData,
      organicData,
      chartLoaded,
      loadPaidData,
      loadOrganicData,
      chartFilters,
      filterTimeline,
      clearFilters,
      adAccounts,
      campaigns,
      searchTerms,
      impressionOptions,
      lookbackOptions,
      dropdownPlaceholder,
      showTruncatedTextWithTitle,
    }
  },
  methods: {
  },
}
</script>

<style>

.mxy-10{
  margin-top: 10% !important;
}
canvas{
  height: 600px !important;
}

</style>
