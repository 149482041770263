import { createApp } from 'vue'
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import appConfig from '@/config'
import router from '@/router'
import App from '@/App.vue'
import VueGtag from 'vue-gtag'
import store from '@/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/base.css'
import 'bootstrap'
import '@/styles/app.css'
import tooltip from './directives/tooltip'

const oktaAuth = new OktaAuth(appConfig.oidc)

const app = createApp(App)

app
  .use(VueGtag, {
    config: { id: 'G-8PD85H41V7' },
  })
  .use(router)
  .use(store)
  .directive('tooltip', tooltip)
  .use(OktaVue, {
    oktaAuth,
    onAuthRequired: () => {
      router.push('/login')
    },
    onAuthResume: () => {
      router.push('/login')
    },
  })
  .mount('#app')
