<template>
  <div class="container mx-auto px-4 mt-5">
    <p class="fs-4 fw-bold mb-4 pt-2">
      Client: {{ client ? client.name : "" }}
    </p>

    <google-o-auth-button
      :entityId="Number(clientIdFromRoute)"
      :gAuthType="clientScope"
      @token-added="tokenAddedEvent"
      :clientId="Number(clientIdFromRoute)"
      class="mt-5 mb-5"
    ></google-o-auth-button>

    <div
      v-if="errorMessage"
      class="mt-0 p-4 bg-danger text-white border-radius-5"
      role="alert"
    >
      <strong>{{ errorMessage }}</strong>
    </div>
    <div
      v-if="successMessage"
      class="mt-0 p-4 bg-success text-white border-radius-5"
      role="alert"
    >
      Google OAuth Token added.
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import GoogleOAuthButton from '@/components/gOauth/GoogleOAuthButton.vue'

const route = useRoute()
const oktaAuth = getCurrentInstance().appContext.app.config.globalProperties.$auth
const client = ref(null)
const errorMessage = ref(false)
const successMessage = ref(false)
const clientIdFromRoute = ref(route.params.id)
const signedUrl = ref(route.params.signedUrl)
const clientScope = ref(route.params.scope)
const validSignedUrl = ref(false)

function verifySignedUrl(clientId, clientSignedUrl, scope) {
  const API_URL = `${process.env.VUE_APP_API_DOMAIN}/clients/${clientId}/signed_url/${clientSignedUrl}?scope=${scope}`
  axios.defaults.headers.common.Authorization = `Bearer ${oktaAuth.getAccessToken()}`
  axios
    .get(API_URL)
    .then((response) => {
      validSignedUrl.value = true
      client.value = response.data
    })
    .catch(() => {
      errorMessage.value = 'Invalid client'
      validSignedUrl.value = false
    })
}

function tokenAddedEvent() {
  successMessage.value = true
}

onMounted(() => {
  verifySignedUrl(route.params.id, route.params.signedUrl, route.params.scope)
  clientIdFromRoute.value = route.params.id
  signedUrl.value = route.params.signedUrl
})

</script>
<style scoped>
.border-radius-5 {
  border-radius: 5px;
}
</style>
